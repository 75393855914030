import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { DragOverlay } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { createPanelMap } from '@editor/files/utils/createPanelMap'
import { handleFileDialogEvent } from '@editor/files/utils/fileUtils'
import { DragType } from '@editor/interfaces'
import { useDragSlice } from '@editor/state'
import { PlusIcon } from '@radix-ui/react-icons'

import { FileDialog } from '../FileDialog'

import Panel, { PanelElement } from './Panel'
import PanelItem from './PanelItem'
import { findPanel } from './useMultiPanelViewer'
// import { useMultiPanelViewerHandlers } from './useMultiPanelViewer'

const MultiPanelView = () => {
  const [isClient, setIsClient] = useState(false)
  const { activeId, panels, dragType, setPanels } = useDragSlice()

  useEffect(() => {
    setIsClient(true)
    setPanels(createPanelMap())
  }, [])

  // const {} = useMultiPanelViewerHandlers()

  return (
    // <DndContext
    //   collisionDetection={closestCorners}
    //   onDragStart={onDragStart}
    //   onDragOver={onDragOver}
    //   onDragEnd={onDragEnd}
    //   onDragCancel={onDragCancel}
    //   sensors={sensors}>
    <>
      {/* BANG: Container root will always be defined */}
      {panels['container-root']!.length > 0 ? (
        <div className="flex overflow-x-auto scrollbar">
          {Object.keys(panels).map((panelId, idx) => {
            return (
              <div
                key={panelId}
                className="flex w-full justify-center px-1 border-r-2 border-blueGray-400 border-opacity-10">
                <div
                  id={panelId}
                  className="w-60 scrollbar overflow-y-auto overflow-x-hidden pointer-events-auto select-none shadow-none">
                  <Panel id={panelId} key={panelId}>
                    <SortableContext
                      strategy={verticalListSortingStrategy}
                      items={panels[panelId]!}>
                      {/* BANG (top and bottom): PanelID is from Object.keys  */}
                      {panels[panelId]!.map((child: string) => (
                        <PanelElement
                          parentId={panelId.replace('container-', '')}
                          key={child}
                          id={child}
                          idx={idx}
                        />
                      ))}
                    </SortableContext>
                  </Panel>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="h-full w-full">
          <FileDialog handleFileChange={handleFileDialogEvent}>
            {({ handleFileClick }) => (
              <button
                onClick={handleFileClick}
                className="focus:outline-none py-4 h-full w-full flex items-center justify-center flex-col">
                <PlusIcon className="h-16 w-16 m-0 text-gray-300 opacity-30"></PlusIcon>
                <p className="text-lg text-gray-500 py-4">Add files</p>
              </button>
            )}
          </FileDialog>
        </div>
      )}
      {isClient &&
        dragType === DragType.file &&
        createPortal(
          <DragOverlay adjustScale={true}>
            {activeId && (
              <div className="flex w-full bg-blueGray-700 rounded-md">
                <PanelItem
                  parentId={findPanel(panels, activeId)!}
                  id={activeId}
                  idx={0}></PanelItem>
              </div>
            )}
          </DragOverlay>,
          document.body
        )}
    </>
    // </DndContext>
  )
}

export default observer(MultiPanelView)

import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarSearch
} from 'kbar'
import React from 'react'

import { DefaultActionLoader } from './actions'
import SendFeedback from './feedback'
import { Results } from './Results'

export const KBarProviderWrapper = ({
  children,
  defaultActions = true,
  disableScrollbarManagement = true
}: {
  children: React.ReactNode
  defaultActions?: boolean
  disableScrollbarManagement?: boolean
}) => {
  return (
    <>
      <KBarProvider
        options={{ disableScrollbarManagement: disableScrollbarManagement }}>
        <KBarPortal>
          <KBarPositioner style={{ zIndex: 50 }}>
            <KBarAnimator className="max-w-2xl w-full bg-gray-900 text-gray-300 rounded-lg shadow-lg overflow-hidden">
              <KBarSearch className="px-3 py-4 text-base w-full outline-none border-none bg-gray-900 text-gray-400" />
              <Results></Results>
            </KBarAnimator>
          </KBarPositioner>
        </KBarPortal>
        {children}

        {defaultActions && <DefaultActionLoader></DefaultActionLoader>}
      </KBarProvider>
      <SendFeedback></SendFeedback>
    </>
  )
}

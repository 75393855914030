import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LOGIN_FINISH } from "../../pages/callback";
import { createAnnoMagic } from "../../util/magic";
import { useAnnotateUser } from "./useAnnotateUser";
export const useAnnoMagicLogin = () => {
  const [magic, setMagic] = useState(null);
  const {
    fingerprint,
    user,
    getAnnotateUser,
    setTokenCookie,
    annoLogin,
    updateUser,
    loading
  } = useAnnotateUser();
  useEffect(() => {
    const magic2 = createAnnoMagic();
    setMagic(magic2);
    magic2.preload();
  }, []);
  const loginWithProvider = async (provider) => {
    const id = Math.floor(Math.random() * 1e3);
    const url = `${window.location.origin}/socialLogin?provider=${provider}&id=${id}`;
    try {
      const openedWindow = window.open(url);
      if (!openedWindow)
        throw new Error("Could not open new tab");
      console.log(`Waiting for event ${LOGIN_FINISH}_${id}`);
      const event = await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (openedWindow.closed) {
            clearInterval(interval);
            reject(new Error("Window closed"));
          }
        }, 1e3);
        window.addEventListener(`${LOGIN_FINISH}_${id}`, (event2) => {
          clearInterval(interval);
          resolve(event2);
        });
      });
      console.log("logged in", event.detail);
      const { token } = event.detail;
      if (token)
        setTokenCookie(token);
      await getAnnotateUser();
      return true;
    } catch (err) {
      toast.error(`Login failed`);
      console.error("error logging in", err);
      return false;
    }
  };
  const loginWithEmail = async (email) => {
    if (!magic) {
      console.log("Magic not defined");
      throw new Error("Magic not defined");
    }
    const didToken = await magic.auth.loginWithMagicLink({
      email,
      redirectURI: `${window.location.origin}/callback`
    });
    let token;
    if (user == null ? void 0 : user.getAnnotateUser) {
      const { data } = await updateUser({
        variables: { updateUser: { didToken } }
      });
      if ((data == null ? void 0 : data.updateAnnotateUser.__typename) === "AnnoUserOutput") {
        token = data.updateAnnotateUser.token || void 0;
      }
    } else {
      const { data } = await annoLogin({
        variables: { input: { didToken, fingerprint } }
      });
      if ((data == null ? void 0 : data.annoLogin.__typename) === "AnnoLoginSuccess") {
        token = data.annoLogin.token;
      }
    }
    if (token)
      setTokenCookie(token);
    await getAnnotateUser();
  };
  const ready = !!magic && !!fingerprint && !loading;
  return { ready, loginWithProvider, loginWithEmail };
};

import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'

import { MediaElementId, MediaElementsClipboard } from '@editor/interfaces'
import { useMediaElementArray } from '@editor/media/hooks'

import { useTimelineSelectedElements } from '../redux'

import { copy } from './copy'
import { paste } from './paste'

export const useCopy = (overWriteIds?: MediaElementId[]) => {
  const timelineSelectedElements = useTimelineSelectedElements()

  const mediaElements = useMediaElementArray(
    overWriteIds || timelineSelectedElements
  )

  const handleCopy = useCallback(
    (e?: ClipboardEvent) => {
      if (mediaElements.length > 0) {
        const clipboard = MediaElementsClipboard(mediaElements)

        copy(clipboard, e)
      }
    },
    [mediaElements]
  )

  return { handleCopy }
}

export const useHandleClipboardEvents = () => {
  const { handleCopy } = useCopy()

  const handlePaste = useCallback((e: ClipboardEvent) => {
    toast('Handling paste')
    paste(undefined, e)
  }, [])

  useEffect(() => {
    document.addEventListener('copy', handleCopy)

    return () => {
      document.removeEventListener('copy', handleCopy)
    }
  }, [handleCopy])

  useEffect(() => {
    document.addEventListener('paste', handlePaste)

    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])
}

import { observer } from 'mobx-react'
import React from 'react'

import { MediaType, ThumbnailType } from '@editor/interfaces'
import { thumbnailStore } from '@editor/state'
import { ExclamationCircleIcon, MusicNoteIcon } from '@heroicons/react/outline'

import { FileItemProps } from './types'
/***
 * TODO: Fix duration and size for FileItem
 */
const FileItem = ({ id, type, offline }: FileItemProps) => {
  const icon = thumbnailStore.getThumbnail(id)

  return (
    <>
      {offline ? (
        <div className="bg-red-600 bg-opacity-10 bg-contain flex flex-col rounded w-full h-20 items-center justify-center py-1 text-red-500 text-xs font-light">
          <ExclamationCircleIcon className="stroke-current stroke-2 w-12 h-12" />
        </div>
      ) : type === MediaType.audio ? (
        <div className="bg-blueGray-700 bg-contain flex flex-col rounded w-full h-20 items-center justify-center px-2 py-1 text-blueGray-500 text-xs font-light">
          <MusicNoteIcon className="stroke-current stroke-2" />
          <div className="flex flex-row justify-between w-full">
            {/* <p style={{ textShadow: 'rgba(30, 41, 59, 0.3) 1px 0 2px ' }}>
              {duration}
            </p>
            <p style={{ textShadow: 'rgba(30, 41, 59, 0.3) 1px 0 2px ' }}>
              {size}
            </p> */}
          </div>
        </div>
      ) : (
        <div
          className="flex flex-row rounded w-full h-20 items-end justify-between text-gray-50 text-xs"
          style={{
            backgroundImage:
              icon?.type === ThumbnailType.Image ? `url("${icon.image}")` : '',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }}>
          <div className="flex backdrop-filter backdrop-blur-sm bg-black py-1 px-2 justify-between bg-opacity-30 w-full">
            {/* <p>{duration}</p> */}
            {/* <p>{size}</p> */}
          </div>
        </div>
      )}
    </>
  )
}

export default observer(FileItem)

import { EndPoints, setPlaybackRateType } from '@editor/interfaces'
import { getRealEnd } from '@editor/utils'

import {
  mediaStore,
  useAppSelector,
  useMediaSlice,
  useTimeline
} from '../../index'

// eslint-disable-next-line no-unused-vars
type setVideoPoints = (point: EndPoints, value: number) => void

/**
 * Wrapper hook for using media elements cleanly without having to pass the idea for each operation
 * @param id
 */
export const useMediaElement = (id: string, getMedia?: boolean) => {
  const { totalDuration } = useTimeline()

  const mediaElement = useAppSelector((state) => state.media.mediaElements[id])

  const { setEndpoints, setPlaybackRate } = useMediaSlice(id)

  const realEnd = getRealEnd(mediaElement)

  const setEndPointsWrapper: setVideoPoints = (point, value) => {
    setEndpoints({ id, point, value })
  }

  const setPlaybackRateWrapper = (
    point: setPlaybackRateType['point'],
    value: setPlaybackRateType['value']
  ) => {
    setPlaybackRate({ id, value: value, point: point })
  }

  if (!mediaElement) return undefined
  return {
    ...mediaElement,
    setEndPoints: setEndPointsWrapper,
    setPlaybackRate: setPlaybackRateWrapper,
    totalDuration,
    media:
      getMedia && mediaElement
        ? mediaStore.mediaObject(mediaElement.mediaId)
        : undefined,
    realEnd: realEnd
  }
}

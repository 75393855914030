import React from 'react'

import { Transition } from '@headlessui/react'

const FileSearch = ({
  search
}: {
  search: boolean
  setSearch: (_newVal: boolean) => void
}) => {
  return (
    <Transition
      show={search}
      enter="transform transition ease-in-out duration-300"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-700"
      leaveFrom="-translate-x-full"
      leaveTo="translate-x-0">
      <Transition.Child>
        <div className="flex-1 bg-transparent flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
          <div className="max-w-lg w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <input
                id="search"
                name="search"
                className="block w-full px-3 py-2 text-gray-50 border-b-2 border-indigo-500 leading-5 bg-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                placeholder="Search Files"
                type="search"
              />
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  )
}

export default FileSearch

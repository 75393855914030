import { uploadAsset } from "@editor/api/cdn/upload";
import { GDRIVE_CF_URL } from "./const";
const API_URL = GDRIVE_CF_URL;
export const toHevcId = (id) => `hevc-${id}`;
export const uploadHevc = (id, file) => uploadAsset(id, file);
export const transcodeHevc = async (id, file) => {
  const hevcId = toHevcId(id);
  await uploadHevc(hevcId, file);
  const { location } = await fetch(API_URL + "hevc", {
    method: "POST",
    body: JSON.stringify({ id: hevcId })
  }).then((res) => res.json());
  return location;
};

import { ActionId, ActionImpl, KBarResults, useMatches } from 'kbar'
import React from 'react'

import { classNames } from '@editor/utils/classnames'
import { BackspaceIcon } from '@heroicons/react/outline'
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'

export const Results = () => {
  const { results, rootActionId } = useMatches()

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <SectionItem item={item}></SectionItem>
        ) : (
          <ResultItem
            action={item}
            active={active}
            // @ts-expect-error
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  )
}

// eslint-disable-next-line react/display-name
const SectionItem = React.forwardRef(
  ({ item }: { item: string }, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        style={{ padding: '0.875rem 1rem' }}
        className="text-xs uppercase text-gray-400 text-opacity-80">
        {item}
      </div>
    )
  }
)

const Shortcut = ({ shortcut }: { shortcut: string }) => {
  switch (shortcut) {
    case 'backspace':
      return (
        <kbd className="px-1 py-2 rounded text-sm bg-black bg-opacity-10">
          <BackspaceIcon className="w-5 h-5" />
        </kbd>
      )
    default:
      return (
        <kbd className="px-1 py-2 rounded text-sm bg-black bg-opacity-10">
          {shortcut}
        </kbd>
      )
  }
}

// eslint-disable-next-line react/display-name
const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId
    }: {
      action: ActionImpl
      active: boolean
      currentRootActionId: ActionId
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId
      )
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    return (
      <div
        ref={ref}
        style={{ padding: '0.875rem 1rem' }}
        className={classNames(
          'flex border-l-2 items-center justify-between cursor-pointer',
          active
            ? 'bg-black bg-opacity-20 border-gray-200'
            : 'bg-transparent border-transparent'
        )}>
        <div className="flex items-center text-sm gap-4">
          {action.icon ? (
            action.icon
          ) : (
            <ArrowNarrowRightIcon className="w-4 h-4"></ArrowNarrowRightIcon>
          )}
          <div className="flex flex-col">
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span className="text-opacity-80 mr-2">
                      {ancestor.name}
                    </span>
                    <span className="mr-2">&rsaquo;</span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span className="text-xs text-opacity-80">{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div aria-hidden className="grid grid-flow-col gap-1">
            {action.shortcut.map((shortcut) => (
              <Shortcut key={shortcut} shortcut={shortcut} />
            ))}
          </div>
        ) : null}
      </div>
    )
  }
)

/* eslint-disable simple-import-sort/imports */
/***
 * These are default actions which should be on every page
 */

import { Action, useRegisterActions } from 'kbar'
import React from 'react'
import toast from 'react-hot-toast'

import { getProjectUrl } from '@editor/utils/getProjectUrl'
import { BugIcon, DiscordIcon } from '@editor/components/icons'
import { client, notNull } from '@editor/graphql'
import { modeStore } from '@editor/state'
import { useKbarFeedback } from '@editor/state/quickState'
import {
  isDev,
  removeTokenCookie,
  redirect,
  uint8ArrayToBase64
} from '@editor/utils'
import {
  CreateFromSaveFileDocument,
  CreateFromSaveFileMutation,
  CreateFromSaveFileMutationVariables,
  LogoutDocument
} from '@graphql/schema/client'
import {
  CogIcon,
  HomeIcon,
  LogoutIcon,
  UploadIcon,
  MailIcon,
  SupportIcon,
  ChatIcon
} from '@heroicons/react/outline'

const devActions: Action[] = isDev
  ? [
      {
        id: 'clearMultiplayerState',
        name: 'Clear State',
        keywords: 'clear multiplayer state',
        perform: () => {
          // @ts-ignore
          window.multiplayerStore.multiplayerInstance.cleanState()
          window.location.reload()
        },
        section: 'Developer Options'
      }
    ]
  : []

export const defaultActions: Action[] = [
  {
    id: 'homeAction',
    name: 'Home',
    keywords: 'back',
    section: 'navigation',
    perform: () => {
      modeStore.setBeforeUnload(false)
      window.open('/', '_self')?.focus()
    },
    icon: <HomeIcon className="w-4 h-4"></HomeIcon>
  },
  {
    id: 'settings',
    name: 'User settings',
    keywords: 'settings',
    section: 'User settings',
    icon: <CogIcon className="w-4 h-4"></CogIcon>
  },
  {
    id: 'logOut',
    name: 'Log out',
    keywords: 'log out',
    parent: 'settings',
    section: '',
    icon: <LogoutIcon className="w-4 h-4"></LogoutIcon>,
    perform: () => {
      toast.promise(client.mutate({ mutation: LogoutDocument }), {
        loading: 'Logging out...',
        success: () => {
          removeTokenCookie()
          redirect('/login')
          return 'Logged out'
        },
        error: 'Unable to log out'
      })
    }
  },
  {
    id: 'help',
    name: 'Support',
    keywords: 'help',
    section: 'Help',
    subtitle: 'Get help with anything',
    icon: <SupportIcon className="w-4 h-4"></SupportIcon>
  },
  {
    id: 'feedback',
    name: 'Feedback',
    keywords: 'feedback',
    section: 'Help',
    subtitle: 'Give us feedback',
    icon: <ChatIcon className="w-4 h-4"></ChatIcon>
  },
  {
    id: 'bug',
    name: 'Report a bug',
    keywords: 'bug',
    section: 'Help',
    parent: 'help',
    subtitle: 'Help us improve!',
    icon: <BugIcon className="w-4 h-4"></BugIcon>
  },
  {
    id: 'sendFeedback',
    name: 'Send Feedback',
    section: '',
    parent: 'feedback',
    perform: () => {
      useKbarFeedback.setState({ feedbackModal: true })
    }
  },
  {
    id: 'discord',
    name: 'Discord',
    keywords: 'discord',
    section: '',
    parent: 'help',
    subtitle: 'Reach out to the team/community on discord for support',
    icon: <DiscordIcon className="w-4 h-4"></DiscordIcon>,
    perform: () => {
      window?.open('https://modfy.video/discord', '_blank')?.focus()
    }
  },
  {
    id: 'email',
    name: 'Email',
    keywords: 'email',
    section: '',
    parent: 'help',
    subtitle: 'Reach out for help via email',
    icon: <MailIcon className="w-4 h-4"></MailIcon>,
    perform: () => {
      window?.open('mailto:support@modfy.video', '_blank')?.focus()
    }
  },
  {
    id: 'loadFileSaveData',
    name: 'Load project from save file',
    keywords: 'Load project from save file',
    section: '',
    icon: <UploadIcon className="w-4 h-4"></UploadIcon>,
    perform: loadFileSaveData
  },
  {
    id: 'devConsole',
    name: 'Toggle dev console',
    keywords: 'dev console',
    section: '',
    icon: <CogIcon className="w-4 h-4"></CogIcon>,
    perform: () => {
      modeStore.toggleDevConsole()
    }
  },
  ...devActions
]

async function loadFileSaveData() {
  return new Promise<void>((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    const cb = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.item(0)
      if (!file) return reject(new Error('No input file'))
      const base64 = await file
        .arrayBuffer()
        .then((buf) => uint8ArrayToBase64(new Uint8Array(buf)))
      await loadFileSaveDataMutation(base64)
      resolve()
    }
    // @ts-expect-error
    input.onchange = cb
    input.click()
  })
}

async function loadFileSaveDataMutation(base64FileSave: string) {
  try {
    console.log('Running client mutation')

    toast('Packing project data...')
    const { data } = await client.mutate<
      CreateFromSaveFileMutation,
      CreateFromSaveFileMutationVariables
    >({
      mutation: CreateFromSaveFileDocument,
      variables: {
        saveFile: base64FileSave
      }
    })

    if (!data || !data.createFromSaveFile)
      throw new Error('GraphQL mutation failed')

    const owner = notNull(data.createFromSaveFile.owner)
    redirect(getProjectUrl({ ...data.createFromSaveFile, owner: owner }))
  } catch (err) {
    console.error('Create save state error', err)
    toast.error('Failed to create save state!')
  }
}

/**
 * Component that can be loaded below the children so that the default actions load below custom actions
 */
export const DefaultActionLoader = () => {
  useRegisterActions(defaultActions)

  return null
}

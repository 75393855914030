import { useState } from "react";
export const useLoading = () => {
  const [loading, setLoading] = useState(false);
  const withLoading = (func) => {
    return async (...args) => {
      setLoading(true);
      try {
        await func(...args);
      } catch (err) {
        console.error("withLoading func error", err);
      }
      setLoading(false);
    };
  };
  return { loading, withLoading, setLoading };
};

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { folder, levaStore } from "leva";
import { makeAutoObservable } from "mobx";
import { nanoid } from "nanoid";
import posthog from "posthog-js";
import toast from "react-hot-toast";
import {
  adminLogger,
  setAdminValue,
  updateAdminSchema
} from "@editor/admin/adminStore";
import { typedMutation } from "@editor/graphql";
import {
  AnnoCreationStage,
  MediaPlaybackState,
  SyncedMediaPlaybackState
} from "@editor/interfaces";
import {
  annotateModeStore,
  Audio,
  mediaStore,
  multiplayerStore,
  ThirdPartyEmbed,
  Video
} from "@editor/state";
import {
  AnnotateProjectDocument,
  CreateAnnotateProjectDocument,
  CreateAnnotateProjectVersionDocument
} from "@graphql/schema/client";
import { saveAnnotateProjectState } from "./annotate";
import { fileInputId, needsUpload } from "./selection";
class CreateAnnoStore {
  constructor() {
    this.name = "";
    this.stages = [
      AnnoCreationStage.Selection,
      AnnoCreationStage.NameProject
    ];
    this.currentStage = 0;
    this.loading = false;
    this.creatingProject = false;
    this.inputSelectionType = null;
    this.url = "";
    this.setName = (n) => {
      this.name = n;
      setAdminValue("CreateAnno.name", n);
    };
    this.setUrl = (u) => {
      this.url = u;
      setAdminValue("CreateAnno.url", u);
    };
    this.setFileInput = (f) => this.fileInput = f;
    this.addStage = (stage, next, push) => {
      if (push) {
        this.stages.push(stage);
      } else {
        this.stages.splice(this.currentStage + 1, 0, stage);
      }
      if (next)
        this.nextStage();
    };
    this.setInputSelectionType = (n) => {
      this.inputSelectionType = n;
      this.addStage(AnnoCreationStage.Embed);
    };
    this.setLoading = (s) => {
      if (s === false) {
        this.stages = this.stages.filter((s2) => s2 !== AnnoCreationStage.Loading);
        this.loading = false;
        setAdminValue("CreateAnno.loading", false);
      } else {
        this.loading = true;
        this.addStage(AnnoCreationStage.Loading, false, true);
        setAdminValue("CreateAnno.loading", true);
      }
    };
    this.popStage = (pop) => {
      if (pop) {
        this.stages.pop();
      } else {
        this.stages.splice(this.currentStage, 1);
      }
      this.currentStage -= 1;
      levaStore.set({ "CreateAnno.currentStage": this.currentStage.toString() }, true);
    };
    this.nextStage = () => {
      this.currentStage += 1;
      levaStore.set({ "CreateAnno.currentStage": this.currentStage.toString() }, true);
    };
    this.createMedia = async () => {
      const { fileInput, setLoading } = this;
      if (!fileInput)
        return;
      let media;
      const id = fileInputId(fileInput);
      if (fileInput._type === "thirdPartEmbed") {
        media = new ThirdPartyEmbed({
          _id: nanoid(),
          name: "Embed",
          embedType: fileInput.embedType,
          embedUrl: fileInput.url,
          duration: 0
        });
      } else if (fileInput.file.type.includes("video")) {
        const props = await Video.getFileProps(fileInput.file);
        if (id)
          props._id = id;
        media = new Video(__spreadValues({}, props));
      } else if (fileInput.file.type.includes("audio")) {
        const props = await Audio.getFileProps(fileInput.file);
        if (id)
          props._id = id;
        media = new Audio(__spreadValues({}, props));
      }
      if (!media) {
        toast.error("Invalid file format, only audio or video files are allowed");
        this.creatingProject = false;
        setLoading(false);
        return;
      }
      if (fileInput._type !== "thirdPartEmbed" && fileInput.file) {
        media.setFile(fileInput.file);
      }
      return media;
    };
    this.addMediaAndUpload = async (media) => {
      const { fileInput } = this;
      if (!fileInput)
        return;
      mediaStore.addMedia(media);
      if (needsUpload(fileInput)) {
        media.upload();
      } else {
        media.updateSyncedMediaPlaybackState(SyncedMediaPlaybackState.uploaded);
        await media.updateMediaPlaybackState(MediaPlaybackState.playable);
      }
    };
    this.resetCreateState = () => {
      this.stages = [AnnoCreationStage.Selection, AnnoCreationStage.NameProject];
      this.currentStage = 0;
      this.fileInput = void 0;
      this.projectId = void 0;
      this.version = void 0;
      this.loading = false;
      this.inputSelectionType = null;
      this.url = "";
      this.name = "";
      this.creatingProject = false;
    };
    this.createAnnoProject = async (fingerprint, userId, userName, router) => {
      var _a, _b;
      const { addStage, fileInput, name } = this;
      if (fingerprint && fileInput) {
        if (!userName || !userId) {
          if (!createAnnoStore.stages.includes(AnnoCreationStage.Onboard)) {
            addStage(AnnoCreationStage.Onboard, true);
          }
          return;
        }
        if (this.stages[this.currentStage] !== AnnoCreationStage.Loading) {
          console.log("not in loading stage");
          return;
        }
        if (this.creatingProject) {
          console.log("already creating a project");
          return;
        }
        this.creatingProject = true;
        const media = await this.createMedia();
        if (!media)
          return;
        const project = await typedMutation().mutate({
          mutation: CreateAnnotateProjectDocument,
          variables: {
            projectProps: {
              ownerId: userId,
              mediaId: media._id,
              name: name || `Untitled Project - ${new Date().toDateString()}`
            }
          },
          refetchQueries: [AnnotateProjectDocument]
        });
        adminLogger("CreateAnno", "Creating annotate project");
        if (!((_a = project.data) == null ? void 0 : _a.createAnnotateProject) || !userId) {
          throw Error("Unable to create project");
        }
        const { id: projectId, version } = (_b = project.data) == null ? void 0 : _b.createAnnotateProject;
        posthog.capture("create_anno", {
          annoType: fileInput._type,
          id: projectId,
          version: 1
        });
        await multiplayerStore.startAnnotateYjs({
          projectId,
          version,
          user: { id: userId, username: userName },
          firstStart: true,
          saveFn: (doc) => {
            return saveAnnotateProjectState(doc, projectId, version, userId);
          }
        });
        adminLogger("CreateAnno", "Yjs started");
        await this.addMediaAndUpload(media);
        try {
          const annoUrl = `${window.location.origin}/${projectId}/${version}`;
          await navigator.clipboard.writeText(annoUrl);
          toast.success("Copied anno link to clipboard");
        } catch (err) {
          console.log("Not copied", err);
        }
        annotateModeStore.resetLoadState();
        await router.push(`/${projectId}/${version}`, void 0, {
          shallow: true
        });
        this.resetCreateState();
      }
    };
    this.createAnnoNewVersion = async (project, user, router) => {
      var _a, _b;
      if (!project.getAnnotateProject || !user.getAnnotateUser)
        return;
      if (this.creatingProject) {
        console.log("already creating a project");
        return;
      }
      this.creatingProject = true;
      const { setLoading } = this;
      const media = await this.createMedia();
      if (!media) {
        this.creatingProject = false;
        return;
      }
      const projectId = project.getAnnotateProject.id;
      const versions = project.getAnnotateProject.versions || [];
      const lastVer = versions[versions.length - 1];
      const newVersion = ((lastVer == null ? void 0 : lastVer.version) || 0) + 1;
      const result = await typedMutation().mutate({
        mutation: CreateAnnotateProjectVersionDocument,
        variables: {
          input: {
            projectId,
            version: newVersion
          }
        },
        refetchQueries: [AnnotateProjectDocument]
      });
      if (!((_a = result.data) == null ? void 0 : _a.createAnnotateProjectVersion)) {
        toast.error("Could not create a new version");
        setLoading(false);
        this.creatingProject = false;
        return;
      }
      posthog.capture("create_anno_version", {
        id: projectId,
        version: 1
      });
      const version = (_b = result.data) == null ? void 0 : _b.createAnnotateProjectVersion.version;
      console.log(`${version} version created`);
      const userId = user.getAnnotateUser.id;
      await multiplayerStore.switchAnnotateProjectVersion({
        projectId,
        version,
        saveFn: (doc) => saveAnnotateProjectState(doc, projectId, version, userId),
        user: {
          id: user.getAnnotateUser.id,
          username: user.getAnnotateUser.name
        }
      });
      this.addMediaAndUpload(media);
      levaStore.dispose();
      router.push(`/${projectId}/${version}`, void 0, {
        shallow: true
      });
      this.resetCreateState();
      return true;
    };
    makeAutoObservable(this);
    updateAdminSchema({
      CreateAnno: folder({
        currentStage: { value: "", editable: false },
        url: { value: "", editable: false },
        name: { value: "", editable: false },
        loading: { value: false, editable: false }
      })
    });
    setAdminValue("CreateAnno.currentStage", "0");
    adminLogger("CreateAnno", "hi");
  }
}
export const createAnnoStore = new CreateAnnoStore();

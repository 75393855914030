import React from 'react'

export const BackgroundElementModeIcon = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 14.5C4 10.3333 6.66667 6.58333 12 2C17.3333 6.58333 20 10.3333 20 14.5C20 14.5 20 14.5 20 14.5C20 14.5324 19.9998 14.5647 19.9993 14.5969C19.9928 15.0809 19.9374 15.5538 19.8375 16.0115C19.0915 19.4291 15.866 22 12 22C7.58172 22 4 18.6422 4 14.5C4 14.5 4 14.5 4 14.5ZM18.2538 13.3812C15.948 12.4989 13.4401 13.5864 11.0926 14.6043C9.1662 15.4397 7.34786 16.2281 5.83789 15.8429C5.72572 15.409 5.66667 14.9589 5.66667 14.5C5.66667 11.3667 7.504 8.28334 12 4.22048C15.9607 7.7996 17.8581 10.6186 18.2538 13.3812Z"
        fill="currentColor"
      />
    </svg>
  )
}

// This is the context menu for when the timeline element
import { observer } from 'mobx-react'
import React from 'react'

import { fileStore } from '@editor/state'
import { BackspaceIcon, ChevronRightIcon } from '@heroicons/react/outline'

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTriggerItem,
  RightSlot
} from '../Primitives'

const FileElementMenu = ({
  id,
  parentId
}: {
  id: string
  parentId?: string
}) => {
  return (
    <>
      <ContextMenuContent
        sideOffset={5}
        //   @ts-ignore
        align="end">
        <ContextMenuItem disabled>Cut</ContextMenuItem>
        <ContextMenuItem disabled>Copy</ContextMenuItem>
        <ContextMenuItem disabled>
          Duplicate <RightSlot>Cntrl+D</RightSlot>
        </ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            fileStore.remove(id, parentId)
          }}>
          Delete{' '}
          <RightSlot>
            <BackspaceIcon className="w-5 h-5"></BackspaceIcon>
          </RightSlot>
        </ContextMenuItem>
        <ContextMenuItem>Rename</ContextMenuItem>

        <ContextMenuSeparator />
        <ContextMenu>
          <ContextMenuTriggerItem disabled>
            More Tools
            <RightSlot>
              <ChevronRightIcon className="w-4 h-4" />
            </RightSlot>
          </ContextMenuTriggerItem>
          <ContextMenuContent sideOffset={2} alignOffset={-5}>
            <ContextMenuItem>
              Save Page As… <RightSlot>⌘+S</RightSlot>
            </ContextMenuItem>
            <ContextMenuItem>Create Shortcut…</ContextMenuItem>
            <ContextMenuItem>Name Window…</ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem>Developer Tools</ContextMenuItem>
          </ContextMenuContent>
        </ContextMenu>
      </ContextMenuContent>
    </>
  )
}

export default observer(FileElementMenu)

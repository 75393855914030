import React from 'react'

export const TextModeIcon = ({
  className = 'w-5 h-5 2xl:w-6 2xl:h-6'
}: {
  className?: string
}) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="4.36364" height="4.36364" fill="currentColor" />
      <rect x="19.6367" width="4.36364" height="4.36364" fill="currentColor" />
      <rect
        x="19.6367"
        y="19.6364"
        width="4.36364"
        height="4.36364"
        fill="currentColor"
      />
      <rect y="19.6364" width="4.36364" height="4.36364" fill="currentColor" />
      <path
        d="M10.731 8.69667V18.1818H13.2684V8.69667H16.727V6.54541H7.27246V8.69667H10.731Z"
        fill="currentColor"
      />
      <line
        x1="4.36328"
        y1="22.0454"
        x2="19.636"
        y2="22.0454"
        stroke="currentColor"
      />
      <line
        x1="4.36328"
        y1="1.68176"
        x2="19.636"
        y2="1.68176"
        stroke="currentColor"
      />
      <line
        x1="1.95508"
        y1="4.36365"
        x2="1.95508"
        y2="19.6364"
        stroke="currentColor"
      />
      <line
        x1="22.3184"
        y1="4.36365"
        x2="22.3184"
        y2="19.6364"
        stroke="currentColor"
      />
    </svg>
  )
}

export default TextModeIcon

// This is the context menu for when the timeline element
import { observer } from 'mobx-react'
import React from 'react'

import { MediaElementId } from '@editor/interfaces'
import { useMediaElement } from '@editor/media/hooks'
import { mediaStore } from '@editor/state'
import { layers } from '@editor/state/media/helpers/layers'
import { mediaElementNotDefinedError, shortCutMap } from '@editor/utils'
import { BackspaceIcon } from '@heroicons/react/outline'

import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  RightSlot
} from '../Primitives'

const PreviewMenu = ({ id }: { id: MediaElementId }) => {
  const mediaElement = useMediaElement(id, true)

  if (!mediaElement) {
    throw mediaElementNotDefinedError(id)
  }

  return (
    <>
      <ContextMenuContent
        sideOffset={5}
        // @ts-ignore
        align="end">
        <ContextMenuItem disabled>Cut</ContextMenuItem>
        <ContextMenuItem disabled>Copy</ContextMenuItem>
        <ContextMenuItem disabled>
          Duplicate <RightSlot>{shortCutMap().ctrl}+D</RightSlot>
        </ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            mediaStore.removeMediaElement(id)
          }}>
          Delete
          <RightSlot>
            <BackspaceIcon className="w-5 h-5"></BackspaceIcon>
          </RightSlot>
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onSelect={() => {
            layers.bringToFront(mediaElement, mediaStore.tracks)
          }}>
          Bring to front
          <RightSlot>]</RightSlot>
        </ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            layers.sendToBack(mediaElement, mediaStore.tracks)
          }}>
          Send to back
          <RightSlot>[</RightSlot>
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem disabled>Lock/Unlock</ContextMenuItem>
        <ContextMenuItem disabled>Unlock all objects</ContextMenuItem>
      </ContextMenuContent>
    </>
  )
}

export default observer(PreviewMenu)

import { autorun, toJS } from 'mobx'
import { useCallback, useEffect, useState } from 'react'

import { MediaElementId, MediaTrack, MediaType } from '@editor/interfaces'

import {
  mediaStore,
  store,
  unSelectPreviewMedia,
  useAppDispatch,
  useAppSelector,
  usePlaybackSlice,
  usePreviewSelectedMedia
} from '../../index'

export type CurrentMedia = Array<{ id: MediaElementId; type: MediaType }>

/**
 * Get the current media on the screen in reverse order, the bottom most current element will be the first element
 */
export const useCurrentMedia = () => {
  const { playheadInSeconds } = usePlaybackSlice()

  const { mediaElements } = useAppSelector((state) => state.media)

  const dispatch = useAppDispatch()

  const selectedMedia = usePreviewSelectedMedia()

  const [trackState, setTracks] = useState<MediaTrack>({
    prime: [],
    above: [],
    below: []
  })

  const [currentMedia, setCurrentMedia] = useState<CurrentMedia>([])
  const [currentMediaObj, setCurrentMediaObj] = useState<
    Record<string, MediaType>
  >({})

  const [elements, setElements] = useState<
    Record<string, HTMLButtonElement | null>
  >({})

  const findNewCurrentMedia = useCallback(
    (propTrack?: MediaTrack) => {
      // Get media elements fresh from the store
      // because this might be called with previous value of media elements
      // which causes issues in tldraw syncing
      const mediaElements = store.getState().media.mediaElements

      const newCurrentMedia: CurrentMedia = []
      const newCurrentMediaObj: Record<string, MediaType> = {}
      setElements({})

      const tracks = { ...trackState, ...toJS(propTrack) }

      let activeSelectedMedia = false

      const checkOverlap = (track: string[]) => {
        track.forEach((elmId) => {
          const mediaElm = mediaElements[elmId]

          if (mediaElm) {
            const { startOnTimeline, duration, type } = mediaElm

            if (
              startOnTimeline <= playheadInSeconds &&
              startOnTimeline + duration >= playheadInSeconds
            ) {
              // The selected media is still active
              if (elmId === selectedMedia.id) {
                activeSelectedMedia = true
              }
              newCurrentMedia.push({ id: mediaElm.id, type })
              newCurrentMediaObj[elmId] = type
            }
          }
        })
      }
      tracks.below.forEach((belowTrack) => checkOverlap(belowTrack))
      checkOverlap(tracks.prime)
      tracks.above.forEach((aboveTrack) => checkOverlap(aboveTrack))

      // console.log('New Current Media', newCurrentMedia)

      if (activeSelectedMedia === false && selectedMedia.id != null) {
        dispatch(unSelectPreviewMedia())
      }

      setCurrentMedia(newCurrentMedia)
      setCurrentMediaObj(newCurrentMediaObj)
    },
    [dispatch, playheadInSeconds, selectedMedia.id, trackState]
  )

  /**
   * This useEffect handles track mutations using mobx's autoRun
   * This will trigger a recompute of media whenever tracks changes
   */
  useEffect(() => {
    const unsub = autorun(() => {
      setTracks(mediaStore.tracks)
      // console.log('Recompute based on track change')
      findNewCurrentMedia(mediaStore.tracks)
    })
    return unsub
  }, [findNewCurrentMedia])

  const callBackRef = (id: string, element: HTMLButtonElement | null) => {
    setElements((c) => {
      return { ...c, [id]: element }
    })
  }

  /**
   * This will trigger a re-render when mediaElements or playhead updates
   *
   * There was a weird bug here with updating and so we changed it to this system
   * Time Spent : 2 hrs
   */
  useEffect(() => {
    findNewCurrentMedia()
  }, [findNewCurrentMedia, mediaElements, playheadInSeconds])

  // console.log('Current media', currentMedia)

  return { currentMedia, currentMediaObj, elements, setElements, callBackRef }
}

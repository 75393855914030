import React from 'react'

const FolderItem = () => {
  return (
    <div className="bg-opacity-10 flex flex-col rounded h-20 items-center justify-center p-1 group-hover:text-blueGray-500 transition duration-75 text-blueGray-700 text-xs font-light">
      <svg
        className="h-full w-full"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H7L9 2H14C15.1046 2 16 2.89543 16 4V10C16 11.1046 15.1046 12 14 12H2C0.895431 12 0 11.1046 0 10V2Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}

export default FolderItem

import { PanelMap } from '@editor/interfaces'
import { fileStore } from '@editor/state/'

export const createPanelMap = (): PanelMap => {
  const panelMap: PanelMap = {}

  if (fileStore) {
    const panels = [fileStore.root, ...fileStore.panelSelections]
    const folders = fileStore.folders

    for (const panelId of panels) {
      if (panelId) {
        panelMap[`container-${panelId}`] =
          folders[panelId]?.children?.map((child) => child) || []
      }
    }
  }
  return panelMap
}

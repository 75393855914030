// This is the context menu for when the timeline element
import { observer } from 'mobx-react'
import React from 'react'

import { fileStore } from '@editor/state'

import { ContextMenuContent, ContextMenuItem } from '../Primitives'

const FileMenu = ({ parentId }: { parentId?: string }) => {
  return (
    <>
      <ContextMenuContent
        sideOffset={5}
        //   @ts-ignore
        align="end">
        <ContextMenuItem
          onSelect={() => {
            const dirId = parentId?.slice(10)

            fileStore.addDir('New folder', dirId || 'root')
          }}>
          New Folder
        </ContextMenuItem>
        <ContextMenuItem disabled>Paste</ContextMenuItem>
        <ContextMenuItem disabled>Select All</ContextMenuItem>
      </ContextMenuContent>
    </>
  )
}

export default observer(FileMenu)

/* This example requires Tailwind CSS v2.0+ */
import axios from 'axios'
import React, { FormEvent, Fragment, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { modeStore } from '@editor/state'
import { useKbarFeedback } from '@editor/state/quickState'
import { Dialog, Transition } from '@headlessui/react'
import { PaperClipIcon } from '@heroicons/react/outline'

export default function SendFeedback() {
  const open = useKbarFeedback((state) => state.feedbackModal)
  const toggleOpen = useKbarFeedback((state) => state.toggleFeedbackModal)
  const user = useKbarFeedback((state) => state.user)

  const cancelButtonRef = useRef(null)

  const [body, setBody] = useState('')

  const [_files, setFiles] = useState<FileList | null>(null)

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const sendFeedback = async (e: FormEvent) => {
    e.preventDefault()

    toast.promise(axios.post('/api/feedback', { feedback: body, user: user }), {
      loading: 'Sending feedback...',
      success: 'Feedback received!',
      error: 'Failed to send feedback'
    })
    setBody('')
    toggleOpen()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative bg-blueGray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-4 sm:max-w-3xl sm:w-full sm:p-6">
                <form onSubmit={sendFeedback}>
                  <div>
                    <div className="text-left sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-200">
                        Send feedback
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          How can we improve{' '}
                          {modeStore.isAnno ? 'Anno' : 'Modfy'}? If you have a
                          feature request, can you share how you would use it
                          and why it is important to you?
                        </p>
                      </div>
                    </div>
                    <div className="bg-blueGray-500 bg-opacity-10 my-1 w-full h-1"></div>

                    <div className="flex items-start space-x-4 py-2">
                      <div className="min-w-0 flex-1">
                        <div className="border-b border-gray-200 focus-within:border-indigo-600">
                          <label htmlFor="comment" className="sr-only">
                            Add your comment
                          </label>
                          <textarea
                            rows={3}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            className="block w-full border-0 bg-transparent text-gray-200 border-b border-transparent p-0 pb-2 resize-none focus:ring-0 focus:border-indigo-600 sm:text-sm"
                            placeholder="e.g. Can you add support for XYZ integration? We prefer to use this when we are create videos."
                            defaultValue={''}
                          />
                        </div>
                        <div className="pt-2 flex justify-between">
                          <div className="flex items-center space-x-5">
                            <div className="flex w-full items-center space-x-2">
                              <input
                                ref={fileInputRef}
                                type="file"
                                onChange={(e) => {
                                  if (e.target.files) {
                                    setFiles(e.target.files)
                                  }
                                }}
                                className="hidden"></input>
                              <button
                                type="button"
                                onClick={() => {
                                  fileInputRef.current?.click()
                                }}
                                className="w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-300">
                                <PaperClipIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                              <span className="text-sm text-gray-400 hover:text-gray-300">
                                Attach a image, videos or a file
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-4 sm:gap-3 sm:grid-flow-row">
                    <div className="text-xs text-gray-200 col-span-3 col-start-1">
                      You can also email us at{' '}
                      <span className="font-bold">hello@modfy.video</span>. We
                      {` can't`} respond to every request but we read them all
                    </div>
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-4 sm:text-sm">
                      Send Feedback
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { useEffect, useState } from 'react'

import {
  MediaElementId,
  MediaElementInterface,
  MediaTrack,
  TrackType
} from '@editor/interfaces'
import { checkMediaElementExists, warnError } from '@editor/utils'
import { getTrackList } from '@editor/utils/getTrackList'

import { mediaStore, useAppSelector } from '../../index'
import { getTrackFromType } from '../helpers/tracks'

export const useMediaElementArray = (ids: MediaElementId[]) => {
  const mediaElements = useAppSelector((state) => state.media.mediaElements)

  const [mediaElementsArr, setMediaElementsArr] = useState<
    MediaElementInterface[]
  >([])

  const idList = ids.join('-')
  const mediaElmList = Object.keys(mediaElements).join('-')

  useEffect(() => {
    const mediaElementsArr = ids
      .map((id) => {
        const mediaElement = mediaElements[id]

        if (!mediaElement) {
          warnError(`Media element with id ${id} does not exist`)
        }

        return mediaElement
      })
      .filter(Boolean) as MediaElementInterface[]

    setMediaElementsArr(mediaElementsArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idList, mediaElmList])

  return mediaElementsArr
}

/**
 * Hook that returns a mediaElemArr state which makes an array of MediaElementInterfaces from the trackID
 * @param tracks : all the tracks
 * @param trackType : which track id - above, below or prime
 * @param trackId - the index of the track within the trackType
 * @returns {@link MediaElementInterface[]}
 */
export const useMediaElementsFromTrack = (
  tracks: MediaTrack,
  trackType: TrackType,
  trackId = 0
) => {
  const mediaElements = useAppSelector((state) => state.media.mediaElements)

  const [mediaElmArr, setMediaElmArr] = useState<MediaElementInterface[]>([])

  const trackList = getTrackList(tracks)

  useEffect(() => {
    const track = getTrackFromType(tracks, trackType, trackId)
    if (track) {
      const newMediaElmArr = track.map((id) =>
        checkMediaElementExists(mediaElements, id)
      )
      setMediaElmArr(newMediaElmArr)
    }
  }, [tracks, mediaElements, trackType, trackId, trackList])

  const moveElementInTrack = (from: number, to: number) => {
    const track = getTrackFromType(tracks, trackType, trackId)
    if (track) {
      const newArray = track.slice()

      const fromSplice = newArray.splice(from, 1)[0]

      if (!fromSplice) {
        warnError('Move element in track fromSplice is not defined')
        return
      }

      newArray.splice(to < 0 ? newArray.length + to : to, 0, fromSplice)

      const newMediaElmArr = newArray.map((id) =>
        checkMediaElementExists(mediaElements, id)
      )
      setMediaElmArr(newMediaElmArr)

      mediaStore.updateSingleTrack(newArray, trackType, trackId)
    }
  }

  return { mediaElmArr, moveElementInTrack }
}

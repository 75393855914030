import React from 'react'

import { KBarProviderWrapper } from './Provider'

export { useKBar } from 'kbar'

const CommandPaletteProvider = ({
  children,
  defaultActions,
  disableScrollbarManagement
}: {
  children: React.ReactNode
  defaultActions?: boolean
  disableScrollbarManagement?: boolean
}) => {
  return (
    <KBarProviderWrapper
      disableScrollbarManagement={disableScrollbarManagement}
      defaultActions={defaultActions}>
      {children}
    </KBarProviderWrapper>
  )
}

export default CommandPaletteProvider

import { FileType, MediaInterface } from '@editor/interfaces'
import { fileStore, mediaStore } from '@editor/state'
import { Audio, Image, Video } from '@editor/state/media'

import { getFileSha256 } from '..'

export const addFiles = async (
  files: FileList | File[],
  dir = fileStore.root,
  ignoreUpload?: boolean,
  ignoreExisting?: boolean
) => {
  const ids: string[] = []

  for (const file of files) {
    const fileSha256 = await getFileSha256(file)

    // We first check if this file already exists by comparing sha256 hash of the file
    // if the file exists we update it on media and re upload
    let existingMedia = false

    if (!ignoreExisting) {
      for (const fileNode of Object.values(fileStore.files)) {
        if (fileNode.data.sha256 === fileSha256) {
          const media = mediaStore.medias[fileNode.id]
          await media?.updateFile(file)
          existingMedia = true
          break
        }
      }
    }
    // continue to next file if an existing file was a match
    if (existingMedia) continue

    const type = file.type

    let media: MediaInterface | undefined

    if (type.includes('video')) {
      const videoProps = await Video.getFileProps(file)
      media = new Video({ ...videoProps })
    } else if (type.includes('audio')) {
      const audioProps = await Audio.getFileProps(file)
      media = new Audio(audioProps)
    } else if (type.includes('image')) {
      const imageProps = await Image.getFileProps(file)
      media = new Image({ ...imageProps })
    }

    if (media) {
      const id = media._id

      media.setFile(file)

      // Will "upload" the file based on if the user is in multiplayer or not
      // if not in multiplayer, then this pseudo uploads the video into the cache and
      // the video can be used from the same url

      if (!ignoreUpload) {
        media.upload()
      }

      fileStore.add(
        {
          ...media,
          type: media.type as FileType,
          sha256: fileSha256,
          contentType: file.type,
          size: file.size,
          id
        },
        id,
        dir
      )

      mediaStore.addMedia(media)

      ids.push(media._id)
    }
  }

  return ids
}

import { observer } from 'mobx-react'
import React, { Fragment, useState } from 'react'

import { ToolTipWrapper } from '@editor/components/Radix/Tooltip'
import { fileStore, modeStore } from '@editor/state'
import { Transition } from '@headlessui/react'
import {
  ChevronDoubleLeftIcon,
  FolderAddIcon,
  PhotographIcon,
  SearchIcon,
  UploadIcon,
  ViewBoardsIcon
} from '@heroicons/react/outline'

import FileSearch from './FileSearch'

const FileControls = () => {
  const [search, setSearch] = useState(false)

  return (
    <Transition.Root show={modeStore.showFilePicker} as={Fragment}>
      <div
        style={{ marginLeft: 0 }}
        className="flex flex-1 py-1 items-center h-full ml-0 self-stretch overflow-hidden">
        <div className="flex-1 flex h-full">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-200"
            leaveFrom="translate-x-full"
            leaveTo="-translate-x-full">
            <div className="items-center w-full h-full border-r-2 border-blueGray-700 border-opacity-10">
              <div className="flex items-center h-full justify-between">
                <div className="text-sm 2xl:text-lg px-4 tracking-wider 2xl:tracking-widest font-bold text-blueGray-500 uppercase">
                  Files
                </div>
                <div className="h-7 gap-x-4 px-6 flex items-center">
                  <ToolTipWrapper text="Add Folder" delay={700}>
                    <button
                      className="rounded-md text-blueGray-500 hover:text-blueGray-700 focus:outline-none"
                      onClick={() =>
                        fileStore.viewerMode === 'multi-panel'
                          ? // Add to the root folder
                            fileStore.addDir('')
                          : // Add to the folder that we have open in
                            // grid view
                            fileStore.addDir(
                              '',
                              fileStore.selectedFoldersBreadcrumb[
                                fileStore.selectedFoldersBreadcrumb.length - 1
                              ]
                            )
                      }>
                      <span className="sr-only">Add folder</span>
                      <FolderAddIcon
                        className="h-6 w-6 2xl:h-6 2xl:w-6 border-blueGray-600"
                        aria-hidden="true"
                      />
                    </button>
                  </ToolTipWrapper>
                  <ToolTipWrapper text="Toggle View" delay={700}>
                    <button
                      className="rounded-md text-blueGray-500 hover:text-blueGray-700 focus:outline-none"
                      onClick={() => fileStore.cycleViewMode()}>
                      <span className="sr-only">Switch to tile view</span>
                      {fileStore.viewerMode === 'multi-panel' ? (
                        <PhotographIcon
                          className="h-5 w-5 2xl:h-6 2xl:w-6 border-blueGray-600"
                          aria-hidden="true"></PhotographIcon>
                      ) : (
                        <ViewBoardsIcon
                          className="h-5 w-5 2xl:h-6 2xl:w-6 border-blueGray-600"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </ToolTipWrapper>
                  <div className="inline-flex justify-center items-center">
                    <ToolTipWrapper text="Search Files" delay={700}>
                      <button
                        onClick={() => {
                          setSearch((c) => !c)
                        }}
                        className="rounded-md text-blueGray-500 hover:text-blueGray-700 focus:outline-none">
                        <span className="sr-only">Search</span>
                        <SearchIcon
                          className="h-5 w-5 2xl:h-6 2xl:w-6"
                          aria-hidden="true"
                        />
                      </button>
                    </ToolTipWrapper>
                    <FileSearch
                      search={search}
                      setSearch={setSearch}></FileSearch>
                  </div>
                  <ToolTipWrapper text="Re-upload missing medias" delay={500}>
                    <button
                      className="rounded-md text-blueGray-500 hover:text-blueGray-700 focus:outline-none"
                      onClick={() => modeStore.setReUploadMediaModal(true)}>
                      <span className="sr-only">Re-upload missing medias</span>
                      <UploadIcon
                        className="h-5 w-5 2xl:h-6 2xl:w-6"
                        aria-hidden="true"
                      />
                    </button>
                  </ToolTipWrapper>
                  <ToolTipWrapper text="Close File Tray" delay={500}>
                    <button
                      className="rounded-md text-blueGray-500 hover:text-blueGray-700 focus:outline-none"
                      onClick={() => modeStore.setFilePicker(false)}>
                      <span className="sr-only">Collapse panel</span>
                      <ChevronDoubleLeftIcon
                        className="h-5 w-5 2xl:h-6 2xl:w-6"
                        aria-hidden="true"
                      />
                    </button>
                  </ToolTipWrapper>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  )
}

export default observer(FileControls)

import { observer } from "mobx-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import isEmail from "validator/lib/isEmail";
import { useLoading } from "../../util/useLoading";
import { useAnnoMagicLogin } from "../hooks/useAnnoMagicLogin";
import { useAnnotateUser } from "../hooks/useAnnotateUser";
import GoogleLoginButton from "./GoogleLoginButton";
const Onboard = ({ onCreated }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const { user, createAnnotateUser, updateUser, fingerprint } = useAnnotateUser();
  const { loginWithProvider, loginWithEmail } = useAnnoMagicLogin();
  const { loading, withLoading } = useLoading();
  const loginWithGoogle = withLoading(async () => {
    const success = await loginWithProvider("google");
    if (success) {
      if (onCreated)
        onCreated();
    }
  });
  const handleOnSubmit = withLoading(async (e) => {
    e.preventDefault();
    if (!/^[a-zA-Z0-9\s_]{0,}$/.test(username) || !username || !fingerprint)
      return toast.error("Invalid name");
    if (email && !isEmail(email))
      return toast.error("Invalid email");
    let needAuth = false;
    if (user == null ? void 0 : user.getAnnotateUser) {
      console.log("Update annotate user");
      const { data } = await updateUser({
        variables: {
          updateUser: { name: username, email: email || void 0 }
        }
      });
      needAuth = (data == null ? void 0 : data.updateAnnotateUser.__typename) === "AnnoLoginNeedAuth";
    } else {
      console.log("Create annotate user");
      const { data } = await createAnnotateUser({
        variables: {
          userProps: {
            name: username,
            fingerprint,
            email: email || void 0
          }
        }
      });
      needAuth = (data == null ? void 0 : data.createAnnotateUser.__typename) === "AnnoLoginNeedAuth";
    }
    if (needAuth) {
      console.log("need auth, login with email");
      await loginWithEmail(email);
    }
    if (onCreated)
      onCreated();
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h3", {
    className: "text-lg leading-6 font-medium text-gray-50"
  }, "Enter a name"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-2 max-w-xl text-sm text-gray-400"
  }, /* @__PURE__ */ React.createElement("p", null, "No signup required")), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleOnSubmit,
    className: "mt-2 pt-2 flex flex-col space-y-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-full"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "username",
    className: "sr-only"
  }, "Name"), /* @__PURE__ */ React.createElement("input", {
    value: username,
    onChange: (e) => setUsername(e.target.value),
    type: "text",
    name: "username",
    autoComplete: "off",
    className: "focus:ring-indigo-500 active:bg-transparent focus:outline-none bg-transparent focus:border-indigo-400 transition text-gray-50 block w-full sm:text-base py-2 border-indigo-500 border-b-2",
    placeholder: "Name"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "w-full"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email",
    className: "sr-only"
  }, "Email (optional)"), /* @__PURE__ */ React.createElement("input", {
    value: email,
    onChange: (e) => setEmail(e.target.value),
    type: "text",
    name: "email",
    autoComplete: "off",
    className: "focus:ring-indigo-500 active:bg-transparent focus:outline-none bg-transparent focus:border-indigo-400 transition text-gray-50 block w-full sm:text-base py-2 border-indigo-500 border-b-2",
    placeholder: "Email (optional)"
  })), /* @__PURE__ */ React.createElement("p", {
    className: "pb-4 text-sm text-gray-400"
  }, "Get an email when someone comments"), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    disabled: loading,
    className: "mt-3 w-full inline-flex items-center justify-center px-4 py-2 text-sm border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  }, loading ? "Launching anno..." : /* @__PURE__ */ React.createElement(React.Fragment, null, "Launch Anno \u2192")), /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center py-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "h-px flex-1 bg-gray-400"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mx-2 text-sm text-gray-400"
  }, "OR"), /* @__PURE__ */ React.createElement("div", {
    className: "h-px flex-1 bg-gray-400"
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(GoogleLoginButton, {
    onClick: loginWithGoogle,
    disabled: loading
  }))));
};
export default observer(Onboard);

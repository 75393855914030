import { nanoid } from 'nanoid'
import toast from 'react-hot-toast'

import { MediaElementId, MediaId, PasteLocation } from '@editor/interfaces'
import { warnError } from '@editor/utils'

import {
  addMediaElement,
  createMediaElement,
  createMediaInterface,
  fileStore,
  mediaStore,
  store
} from '..'

import { JSONClipboard } from './copy'
import { getClipboard } from './idbClipboard'
export const paste = async (location?: PasteLocation, e?: ClipboardEvent) => {
  if (location) {
    toast('Pasting to locations is ignored and not implemented')
  }

  console.log('Handling paste')

  const pasteTextAsSvg = async (_text: string) => {
    // To implement
  }

  const pasteTextAsShape = (_text: string) => {
    // To implement
  }

  const pasteAsHTML = (html: string) => {
    try {
      const maybeJson = html.match(/<modfy>(.*)<\/modfy>/)?.[1]

      if (!maybeJson) return

      const json: JSONClipboard = JSON.parse(maybeJson)

      if (json.type === 'modfy/clipboard') {
        // Insert content

        // Add files to store
        const medias = json.medias

        for (const media of medias) {
          const mediaInterface = createMediaInterface(media)
          if (mediaInterface) {
            mediaStore.handleMultiplayerMediaAdd(mediaInterface)
            const file = json.files[media._id as MediaId]

            if (file) {
              fileStore.add(file, media._id)
            }
          } else {
            warnError('Could not create media interface')
            return
          }
        }

        const tracks = mediaStore.tracks

        // Add mediaElements to store
        for (const mediaElement of json.mediaElements) {
          // BANG: Media should exist here
          const media = mediaStore.medias[mediaElement.mediaId]!
          const mediaElementInterface = createMediaElement(
            {
              ...mediaElement,
              // FOR rn the paste will also be at zero start on timeline
              startOnTimeline: 0,
              id: nanoid() as MediaElementId
            },
            media.isStatic
          )
          store.dispatch(addMediaElement(mediaElementInterface))
          tracks.prime.push(mediaElementInterface.id)
        }

        // Add mediaElements to timeline
        // TODO : Add to container from PasteLocation
        mediaStore.updateTracks(tracks)
        return
      } else {
        warnError('This clipboard is not a modfy clipboard')
        return
      }
    } catch (e) {
      pasteTextAsShape(html)
    }
  }

  if (e !== undefined) {
    const items = e.clipboardData?.items ?? []
    for (const index in items) {
      const item = items[index]

      if (!item) continue

      // TODO
      // We could eventually support pasting multiple files / images,
      // and tiling them out on the canvas. At the moment, let's just
      // support pasting one file / image.

      if (item.type === 'text/html') {
        item.getAsString(async (text) => {
          pasteAsHTML(text)
        })
        return
      } else {
        switch (item.kind) {
          case 'string': {
            item.getAsString(async (text) => {
              if (text.startsWith('<svg')) {
                pasteTextAsSvg(text)
              } else {
                pasteTextAsShape(text)
              }
            })
            return
          }
          case 'file': {
            const file = item.getAsFile()
            if (file) {
              // TODO: Implement file pasting
              return
            }
          }
        }
      }
    }
  }

  getClipboard().then((clipboard) => {
    if (clipboard) {
      pasteAsHTML(clipboard)
    }
  })

  if (navigator.clipboard) {
    const items =
      'read' in navigator.clipboard ? await navigator.clipboard.read() : []

    if (items.length === 0) return

    try {
      for (const item of items) {
        // look for png data.

        const pngData = await item.getType('text/png')

        if (pngData) {
          //   TODO: Implement pasting pngs
          return
        }

        // look for svg data.

        const svgData = await item.getType('image/svg+xml')

        if (svgData) {
          // TODO: Implement pasting svgs
          return
        }

        // look for plain text data.

        const textData = await item.getType('text/plain')

        if (textData) {
          // TODO: Paste as an SVG image if the incoming data is an SVG.
          const text = await textData.text()
          text.trim()

          if (text.startsWith('<svg')) {
            pasteTextAsSvg(text)
          } else {
            pasteTextAsShape(text)
          }

          return
        }
      }
    } catch (e) {
      // noop
    }
  } else {
    // TLDR.warn('This browser does not support the Clipboard API!')
    // if (this.clipboard) {
    //   this.insertContent(this.clipboard, { point, select: true })
    // }
  }
}

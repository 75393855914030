import type { DragEvent } from 'react'
import type React from 'react'

import { fileStore } from '@editor/state'

import { addFiles } from './addFiles'

export const getFileSha256 = async (file: File) => {
  const arrayBuff = await file.arrayBuffer()
  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuff)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

/**
 * Traverses upwards in the element's tree to find the parent element
 * that contains the ID of the panel.
 */
const getPanelParent = (
  el: HTMLElement,
  limit = 20
): HTMLElement | undefined => {
  let curr = el
  let i = 0

  while (!curr.id.includes('container-') && i < limit) {
    i += 1
    // For some reason TS thinks the `ParentNode` type
    // doesn't conform to `HTMLElement`
    // @ts-ignore
    curr = el.parentNode
  }

  if (i >= limit) return undefined

  return curr
}

/**
 * Dropped files will be added to the fileStore and have a corresponding Media class created.
 * If the file is dropped on a panel (the element it is dropped on has a parent with an ID like this: `container-${id}`), then
 * it will be added to that panel/folder, otherwise it is added to the root.
 */
export const handleFileDropEvent = async (e: DragEvent<HTMLDivElement>) => {
  e.preventDefault()

  const el = getPanelParent(e.target as HTMLElement)
  await addFiles(
    e.dataTransfer.files,
    el?.id.replace('container-', '') || fileStore.root
  )
}

export const handleFileDialogEvent = async (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  e.preventDefault()

  if (e.target.files) {
    const el = getPanelParent(e.target as HTMLElement)
    await addFiles(
      e.target.files,
      el?.id.replace('container-', '') || fileStore.root
    )
  }
}

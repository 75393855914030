/* eslint-disable no-unused-vars */
export { default as ContextMenuWrapper } from './ContextMenu'
export * from './Menus/TimelineElementMenu'

export enum ContextMenuType {
  default = 'Default',
  TimelineElement = 'TimelineElement',
  FileElement = 'FileElement',
  FileMenu = 'FileMenu',
  TimelineMenu = 'TimelineMenu',
  PreviewMenu = 'PreviewMenu'
}

export const getProjectUrl = (project: {
  projectName: string
  owner?: { username?: string }
}) => {
  const { owner, projectName } = project

  let org = owner?.username || ''

  org = org.replace(/\s+/g, '-')

  const url = `@${org}/${projectName.replace(/\s+/g, '-')}`
  return url
}

import { observer } from 'mobx-react'
import React, { MouseEventHandler } from 'react'

import { ToolTipWrapper } from '@editor/components/Radix/Tooltip'
import { ContextMenuType, ContextMenuWrapper } from '@editor/context-menu'
import {
  FilePanelIndex,
  isFileNode,
  isFolderNode,
  MediaType
} from '@editor/interfaces'
import { fileStore, mediaStore, modeStore } from '@editor/state'
import {
  isErrored,
  MEDIA_UPLOADING_MESSAGE,
  MISSING_MEDIA_MESSAGE
} from '@editor/state/media'
import { classNames } from '@editor/utils'
import {
  ChevronRightIcon,
  ExclamationCircleIcon,
  FilmIcon,
  FolderIcon,
  MusicNoteIcon,
  PhotographIcon
} from '@heroicons/react/outline'

type ItemProps = {
  id: string
  idx: FilePanelIndex
  parentId: string
}

const PanelItemIcon = ({
  type,
  offline
}: {
  type: MediaType
  offline?: boolean
}) => {
  if (offline) return <ExclamationCircleIcon className="h-5 w-5 text-red-500" />

  switch (type) {
    case MediaType.video:
      return <FilmIcon className="h-5 w-5 text-blueGray-600" />
    case MediaType.image:
      return <PhotographIcon className="h-5 w-5 text-blueGray-600" />
    case MediaType.audio:
      return <MusicNoteIcon className="h-5 w-5 text-blueGray-600" />
    default:
      return null
  }
}

const PanelItem = ({ id, idx, parentId: _parentId }: ItemProps) => {
  const node = fileStore.folders[id] || fileStore.files[id]

  const isFolder = isFolderNode(node)

  const media = node ? mediaStore.medias[node.id] : null
  const isOfflined = media ? isErrored(media.mediaPlaybackState) : false

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (isOfflined) {
      modeStore.setReUploadMediaModal(true)
      return
    }

    // Select this node and set the next panel
    fileStore.setFileSelection(idx, node)
    if (isFolder) return fileStore.setPanelSelection(idx + 1, node)
    fileStore.setPanelSelection(idx + 1, undefined)
    if (!isFolder) {
      fileStore.setSelectedFile(id)
    }
  }

  if (!node) {
    return (
      <div
        key={id}
        className="focus:outline-none rounded-md cursor-pointer py-2 px-2 flex items-center"></div>
    )
  }

  const isSelected = fileStore.fileSelections[idx] === id

  return (
    <ContextMenuWrapper
      menuType={ContextMenuType.FileElement}
      id={id}
      props={{
        parentId: fileStore.getSelectedFolder()
      }}>
      <ToolTipWrapper
        className="block"
        disabled={!isOfflined}
        text={isOfflined ? MISSING_MEDIA_MESSAGE : MEDIA_UPLOADING_MESSAGE}>
        <div
          tabIndex={-1}
          onClick={onClick}
          // prevent double click events going to FileDialog
          onDoubleClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          className={classNames(
            'transition-all select-none w-full duration-300 ease-in-out focus:outline-none shadow-none rounded-md cursor-pointer py-2 px-2 flex items-center justify-start outline-none',
            isOfflined
              ? isSelected
                ? 'bg-red-600 bg-opacity-20'
                : 'bg-red-600 bg-opacity-10'
              : isSelected
              ? 'bg-blueGray-700 bg-opacity-20'
              : 'hover:bg-blueGray-700 hover:bg-opacity-10'
          )}>
          {isFileNode(node) ? (
            <PanelItemIcon type={node.data.type} offline={isOfflined} />
          ) : (
            <FolderIcon className="h-5 w-5 text-blueGray-600" />
          )}
          <div className="flex-grow px-4 py-1 text-coolGray-400 text-opacity-80 text-left text-sm truncate">
            <input
              value={node.label}
              onChange={(e) => {
                fileStore.rename(e.target.value, node.id)
              }}
              className="outline-none bg-transparent w-full"
              spellCheck={false}
            />
          </div>
          {isFolder && node.children ? (
            <div className="flex-grow flex justify-end pr-4">
              <ChevronRightIcon className="h-4 w-4 text-blueGray-500" />
            </div>
          ) : (
            ''
          )}
        </div>
      </ToolTipWrapper>
    </ContextMenuWrapper>
  )
}

export default observer(PanelItem)

import React from 'react'
import { apply, css, keyframes, tw } from 'twind/css'

// import { blackA, violet } from '@radix-ui/colors'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

const styled = <T extends React.JSXElementConstructor<any>>(
  Element: T,
  className: string,
  style?: React.CSSProperties
) => {
  const StyledElement = (props: React.ComponentProps<T>) => {
    return (
      <Element
        {...(props as any)}
        className={props.className || className}
        style={{ ...style, ...props.style }}
      />
    )
  }

  return StyledElement
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const ContentStyle = css(
  apply`border-2 border-black bg-black bg-opacity-60 border-opacity-60 text-gray-50 text-base px-8 py-1 rounded-md shadow-2xl`,
  {
    '@media (prefers-reduced-motion: no-preference)': {
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      willChange: 'transform, opacity',
      '&[data-state="delayed-open"]': {
        '&[data-side="top"]': {
          animationName: slideDownAndFade
        },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade }
      }
    }
  }
)

const StyledContent = styled(TooltipPrimitive.Content, tw(ContentStyle))

const StyledArrow = styled(TooltipPrimitive.Arrow, `text-black fill-current`)

// Exports
export const Tooltip = TooltipPrimitive.Root
export const TooltipTrigger = TooltipPrimitive.Trigger
export const TooltipContent = StyledContent
export const TooltipArrow = StyledArrow

// Your app...

// styled('button', {
//   all: 'unset',
//   fontFamily: 'inherit',
//   borderRadius: '100%',
//   height: 35,
//   width: 35,
//   display: 'inline-flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: violet.violet11,
//   backgroundColor: 'white',
//   boxShadow: `0 2px 10px ${blackA.blackA7}`,
//   '&:hover': { backgroundColor: violet.violet3 },
//   '&:focus': { boxShadow: `0 0 0 2px black` }
// })

export const ToolTipWrapper = ({
  children,
  text,
  delay = 300,
  side = 'bottom',
  disabled = false,
  className = '',
  style,
  onClick,
  contentClassName,
  arrowClassName
}: {
  children: React.ReactNode
  text: string | React.ReactNode
  delay?: number
  side?: 'bottom' | 'left' | 'right' | 'top'
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  contentClassName?: string
  arrowClassName?: string
}) => {
  if (disabled) return <>{children}</>

  return (
    <Tooltip delayDuration={delay}>
      <TooltipTrigger asChild>
        <span className={className} style={style} onClick={onClick}>
          {children}
        </span>
      </TooltipTrigger>

      <StyledContent side={side} sideOffset={5} className={contentClassName}>
        {text}
        <StyledArrow className={arrowClassName || 'text-black fill-current'} />
      </StyledContent>
    </Tooltip>
  )
}

import { useRegisterActions } from 'kbar'
import React from 'react'

import { mediaStore } from '@editor/state'
import { TrashIcon } from '@heroicons/react/outline'

/**
 * Used to show actions and add shortcuts to kbar for a media element
 * @param id id of the media element
 * @param enable should the actions be shown (eg. true when focused/selected otherwise false)
 */
export const useMediaElementActions = (id: string, enable: boolean) => {
  // only show this action if there is a media id and
  useRegisterActions(
    id && enable
      ? [
          {
            id: 'deleteSelectedMedia',
            name: 'Delete selected media',
            keywords: 'delete selected media',
            section: 'Project controls',
            icon: <TrashIcon className="w-4 h-4" />,
            shortcut: ['backspace'],
            perform: () => mediaStore.removeMediaElement(id)
          }
        ]
      : [],
    [id, enable]
  )
}

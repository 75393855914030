import { observer } from 'mobx-react'
import React, { MouseEventHandler } from 'react'

import { fileStore } from '@editor/state'
import { inlineClosure } from '@editor/utils'
import {
  ChevronRightIcon,
  DotsHorizontalIcon,
  FolderIcon
} from '@heroicons/react/outline'

// TODO: Truncate the folder list when the size gets too big. Other folders
// can be accessed via dropdown by clicking the leading ellipsis
const FileBreadcrumb = () => {
  const selections =
    fileStore.viewerMode === 'multi-panel'
      ? fileStore.panelSelections
      : fileStore.selectedFoldersBreadcrumb

  // The first folder is always root and we dont show that in UI
  const viewableSelections = selections.slice(1)

  const handleClick =
    (panelId: string, idx: number): MouseEventHandler =>
    (e) => {
      e.preventDefault()
      if (fileStore.viewerMode === 'thumbnail') {
        fileStore.setSelectedFolder(panelId)
        return
      }

      fileStore.setPanelSelection(idx, panelId)
    }

  return (
    <nav className="flex px-4 z-10 select-none" aria-label="Breadcrumb">
      <ol className="flex items-center">
        <li>
          <div className="flex items-center justify-center">
            <button
              onClick={handleClick('root', 0)}
              className="text-blueGray-400 bg-blueGray-500 p-1 bg-opacity-40 rounded-sm focus:outline-none hover:text-blueGray-500 outline-none">
              <DotsHorizontalIcon
                className="flex-shrink-0 h-4 w-7"
                aria-hidden="true"
              />
              <span className="sr-only">Home</span>
            </button>
          </div>
        </li>
        {viewableSelections.map((panelId, idx) => {
          return panelId
            ? inlineClosure(fileStore.folders[panelId!], (folder) => {
                if (!folder) return null
                return (
                  <li key={panelId} onClick={handleClick(panelId, idx)}>
                    <div className="flex items-center justify-center">
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-coolGray-500"
                        aria-hidden="true"
                      />
                      <div className="flex flex-row items-center">
                        <FolderIcon
                          className="flex-shrink-1 h-5 w-5 text-coolGray-500"
                          aria-hidden="true"
                        />
                        <p className="cursor-pointer max-w-[15ch] ml-2 text-sm font-medium text-coolGray-500 hover:text-coolGray-700 truncate">
                          {folder.label}
                        </p>
                      </div>
                    </div>
                  </li>
                )
              })
            : ''
        })}
      </ol>
    </nav>
  )
}

export default observer(FileBreadcrumb)

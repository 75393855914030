import React from 'react'
import * as colors from 'tailwindcss/colors'
import { apply, css, tw } from 'twind/css'

import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'

// THIS IS HERE INSTEAD OF GLOBALLY IS BECAUSE NEXT is not building with it globally
export const styled = <T extends React.JSXElementConstructor<any>>(
  Element: T,
  className: string,
  style?: React.CSSProperties
) => {
  const StyledElement = (props: React.ComponentProps<T>) => {
    return (
      <Element
        className={className}
        style={{ ...style, ...props.style }}
        {...(props as any)}></Element>
    )
  }

  return StyledElement
}

const StyledContent = styled(
  ContextMenuPrimitive.Content,
  'min-w-[220px] overflow-hidden bg-white border-4 border-white p-1 shadow-2xl rounded-lg'
)

// const StyledContent = styled(ContextMenuPrimitive.Content, {
//   minWidth: 220,
//   maxWidth: 220,
//   backgroundColor: 'white',
//   borderRadius: 6,
//   overflow: 'hidden',
//   padding: 5,
//   boxShadow:
//     '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)'
// })

const itemClassDirective = css(
  {
    all: 'unset'
  },
  apply`flex items-center relative h-6 pl-6 pr-2 select-none py-1 border-2 text-gray-600 rounded-lg focus:bg-gray-100 text-sm`,
  {
    '&[data-disabled]': {
      color: colors.gray[300],
      pointerEvents: 'none'
    },
    '&[data-disabled] > *': {
      color: colors.gray[300],
      pointerEvents: 'none'
    }
  }
)
const itemClasses = `${tw(itemClassDirective)} group`

const StyledItem = styled(ContextMenuPrimitive.Item, itemClasses)

const StyledCheckboxItem = styled(
  ContextMenuPrimitive.CheckboxItem,
  itemClasses
)

const StyledRadioItem = styled(ContextMenuPrimitive.RadioItem, itemClasses)

// const StyledItem = styled(ContextMenuPrimitive.Item, { ...itemStyles })
// const StyledCheckboxItem = styled(ContextMenuPrimitive.CheckboxItem, {
//   ...itemStyles
// })
// const StyledRadioItem = styled(ContextMenuPrimitive.RadioItem, {
//   ...itemStyles
// })

const TriggerItemStyle = tw(
  css(itemClassDirective, {
    '&[data-state="open"]': {
      backgroundColor: colors.gray[100],
      color: colors.gray[600]
    },
    '&[data-state="open"] > *': {
      color: colors.gray[600]
    }
  })
)

const StyledTriggerItem = styled(
  ContextMenuPrimitive.TriggerItem,
  `${TriggerItemStyle} group`
)

// const StyledTriggerItem = styled(ContextMenuPrimitive.TriggerItem, {
//   '&[data-state="open"]': {
//     backgroundColor: colors.indigo[600],
//     color: colors.indigo[900]
//   },
//   ...itemStyles
// })

// const StyledLabel = styled(ContextMenuPrimitive.Label, {
//   paddingLeft: 25,
//   fontSize: 12,
//   lineHeight: '25px',
//   color: colors.gray[900]
// })

const StyledLabel = styled(
  ContextMenuPrimitive.Label,
  'pl-6 text-sm text-gray-600'
)

// const StyledSeparator = styled(ContextMenuPrimitive.Separator, {
//   height: 1,
//   backgroundColor: colors.indigo[500],
//   margin: 5
// })

const StyledSeparator = styled(
  ContextMenuPrimitive.Separator,
  'h-[1px] bg-gray-200 m-1'
)

// const StyledItemIndicator = styled(ContextMenuPrimitive.ItemIndicator, {
//   position: 'absolute',
//   left: 0,
//   width: 25,
//   display: 'inline-flex',
//   alignItems: 'center',
//   justifyContent: 'center'
// })

const StyledItemIndicator = styled(
  ContextMenuPrimitive.ItemIndicator,
  'absolute left-0 w-6 inline-flex justify-center items-center'
)

export const ContextMenu = ContextMenuPrimitive.Root
export const ContextMenuTrigger = ContextMenuPrimitive.Trigger
export const ContextMenuContent = StyledContent
export const ContextMenuItem = StyledItem
export const ContextMenuCheckboxItem = StyledCheckboxItem
export const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup
export const ContextMenuRadioItem = StyledRadioItem
export const ContextMenuItemIndicator = StyledItemIndicator
export const ContextMenuTriggerItem = StyledTriggerItem
export const ContextMenuLabel = StyledLabel
export const ContextMenuSeparator = StyledSeparator

export const RightSlot = ({ children }: { children?: React.ReactNode }) => (
  <div className="ml-auto pl-5 text-gray-600">{children}</div>
)

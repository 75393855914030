// Handle all controls for layers

import {
  MediaElementId,
  MediaElementInterface,
  MediaTrack
} from '@editor/interfaces'
import { collides, warnError } from '@editor/utils'

import { mediaStore, store } from '../../index'

import { track } from './tracks'

/**
 * Move element to top of tracks
 */
const bringToFront = (
  mediaElement: MediaElementInterface,
  tracks: MediaTrack
) => {
  const highestTrack = tracks.above[tracks.above.length - 1]

  if (!highestTrack) {
    warnError("Can't bring to front, highest track is undefined")
    return
  }

  let collided = false

  for (const id of highestTrack) {
    if (id === mediaElement.id) {
      return
    }
    const element = store.getState().media.mediaElements[id]
    if (element) {
      if (collides(element, mediaElement)) {
        collided = true
      }
    }
  }

  const container = track.getTrackFromMediaElementId(mediaElement.id)

  if (!container) {
    warnError('Container is undefined, cannot find track element is on')
    return
  }

  if (collided) {
    // Create a new track

    const newTracks = { ...tracks }

    newTracks.above.push([])

    const mutatedTracks = track.moveMediaElementToTrack(
      mediaElement.id as MediaElementId,
      container,
      { trackType: 'above', index: newTracks.above.length - 1 },
      newTracks
    )

    if (!mutatedTracks) {
      warnError('Could not move tracks')
    }

    mediaStore.updateTracks(mutatedTracks)
  } else {
    // Move to top track
    const mutatedTracks = track.moveMediaElementToTrack(
      mediaElement.id as MediaElementId,
      container,
      { trackType: 'above', index: tracks.above.length - 1 }
    )

    if (!mutatedTracks) {
      warnError('Could not move tracks')
    }
    mediaStore.updateTracks(mutatedTracks)
  }
}

const sendToBack = (
  mediaElement: MediaElementInterface,
  tracks: MediaTrack
) => {
  const lowestTrack = tracks.below[tracks.below.length - 1]

  if (!lowestTrack) {
    warnError("Can't send to back, lowest track is undefined")
    return
  }

  let collided = false

  for (const id of lowestTrack) {
    if (id === mediaElement.id) {
      return
    }
    const element = store.getState().media.mediaElements[id]
    if (element) {
      if (collides(element, mediaElement)) {
        collided = true
      }
    }
  }

  const container = track.getTrackFromMediaElementId(mediaElement.id)

  if (!container) {
    warnError('Container is undefined, cannot find track element is on')
    return
  }

  if (collided) {
    // Create a new track

    const newTracks = { ...tracks }

    newTracks.below.push([])

    const mutatedTracks = track.moveMediaElementToTrack(
      mediaElement.id as MediaElementId,
      container,
      { trackType: 'below', index: newTracks.below.length - 1 },
      newTracks
    )

    if (!mutatedTracks) {
      warnError('Could not move tracks')
    }

    mediaStore.updateTracks(mutatedTracks)
  } else {
    // Move to bottom track
    const mutatedTracks = track.moveMediaElementToTrack(
      mediaElement.id as MediaElementId,
      container,
      { trackType: 'below', index: tracks.below.length - 1 }
    )

    if (!mutatedTracks) {
      warnError('Could not move tracks')
    }
    mediaStore.updateTracks(mutatedTracks)
  }
}

export const layers = {
  bringToFront,
  sendToBack
}

import { observer } from 'mobx-react'
import React from 'react'

import { MediaElementId } from '@editor/interfaces'

import DefaultMenu from './Menus/DefaultMenu'
import FileElementMenu from './Menus/FileElementMenu'
import FileMenu from './Menus/FileMenu'
import PreviewMenu from './Menus/PreviewMenu'
import TimelineElementMenu from './Menus/TimelineElementMenu'
import TimelineMenu from './Menus/TimelineMenu'
import { ContextMenu, ContextMenuTrigger } from './Primitives'
import { ContextMenuType } from '.'

const MenuOptions = ({
  menuType = ContextMenuType.default,
  id,
  props
}: {
  menuType?: ContextMenuType
  id?: string
  props?: { [name: string]: unknown }
}) => {
  // MenuID is structured like `${menuType}-${id}`

  switch (menuType) {
    case ContextMenuType.TimelineElement: {
      if (!id) return null
      return <TimelineElementMenu id={id}></TimelineElementMenu>
    }
    case ContextMenuType.PreviewMenu: {
      if (!id) return null
      return <PreviewMenu id={id as MediaElementId}></PreviewMenu>
    }
    case ContextMenuType.FileElement: {
      if (!id) return null
      return <FileElementMenu id={id} {...props}></FileElementMenu>
    }
    case ContextMenuType.FileMenu: {
      return <FileMenu {...props}></FileMenu>
    }
    case ContextMenuType.TimelineMenu: {
      return <TimelineMenu></TimelineMenu>
    }

    default: {
      return <DefaultMenu></DefaultMenu>
    }
  }
}

const ContextMenuWrapper = ({
  children,
  menuType = ContextMenuType.default,
  id,
  props,
  className
}: {
  children: React.ReactNode
  menuType?: ContextMenuType
  id?: string
  props?: { [name: string]: unknown }
  className?: string
}) => {
  return (
    <ContextMenu>
      <ContextMenuTrigger className={className}>{children}</ContextMenuTrigger>
      <MenuOptions menuType={menuType} id={id} props={props}></MenuOptions>
    </ContextMenu>
  )
}

export default observer(ContextMenuWrapper)

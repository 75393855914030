import { observer } from 'mobx-react'
import React from 'react'

import { useKBar } from '@editor/command-palette'
import { multiplayerStore } from '@editor/state'
import { shortCutMap } from '@editor/utils'
import { CheckIcon, ChevronRightIcon } from '@radix-ui/react-icons'

import {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuItemIndicator,
  ContextMenuSeparator,
  ContextMenuTriggerItem,
  RightSlot
} from '../Primitives'

const DefaultMenu = () => {
  const { query } = useKBar()
  return (
    <ContextMenuContent
      sideOffset={5}
      // @ts-ignore
      align="end">
      <ContextMenuItem
        onClick={() => {
          multiplayerStore.multiplayerInstance?.undo()
        }}>
        Undo <RightSlot>{shortCutMap().ctrl}+Z</RightSlot>
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() => {
          multiplayerStore.multiplayerInstance?.redo()
        }}>
        Redo <RightSlot>{shortCutMap().ctrl}+Shift+Z</RightSlot>
      </ContextMenuItem>

      <ContextMenuSeparator />
      <ContextMenu>
        <ContextMenuTriggerItem>
          View
          <RightSlot>
            <ChevronRightIcon className="w-4 h-4" />
          </RightSlot>
        </ContextMenuTriggerItem>
        <ContextMenuContent sideOffset={2} alignOffset={-5}>
          <ContextMenuCheckboxItem
          // checked={modeStore.showFilePicker}
          // onCheckedChange={modeStore.setFilePicker}
          >
            <ContextMenuItemIndicator>
              <CheckIcon />
            </ContextMenuItemIndicator>
            Files
          </ContextMenuCheckboxItem>
          <ContextMenuCheckboxItem
          // checked={modeStore.showAudioLevels}
          // onCheckedChange={modeStore.setAudioLevels}
          // onCheckedChange={setBookmarksChecked}
          >
            <ContextMenuItemIndicator>
              <CheckIcon />
            </ContextMenuItemIndicator>
            Audio Levels
          </ContextMenuCheckboxItem>

          <ContextMenuCheckboxItem
            disabled={true}
            checked={false}
            // onCheckedChange={setBookmarksChecked}
          >
            <ContextMenuItemIndicator>
              <CheckIcon />
            </ContextMenuItemIndicator>
            Color
          </ContextMenuCheckboxItem>
        </ContextMenuContent>
      </ContextMenu>
      <ContextMenuSeparator />
      <ContextMenuItem onSelect={query.toggle}>
        Command Palette <RightSlot>{shortCutMap().ctrl} + K</RightSlot>
      </ContextMenuItem>
    </ContextMenuContent>
  )
}

export default observer(DefaultMenu)

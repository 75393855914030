import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ToolTipWrapper } from '@editor/components/Radix/Tooltip'
import { ContextMenuType, ContextMenuWrapper } from '@editor/context-menu'
import { DragType, FileDragType, isFolderNode } from '@editor/interfaces'
import { fileStore, mediaStore, modeStore } from '@editor/state'
import {
  isErrored,
  MEDIA_UPLOADING_MESSAGE,
  MISSING_MEDIA_MESSAGE
} from '@editor/state/media'
import { toFixed } from '@editor/utils'

import { useFileNode } from '../../hooks/useFileNode'

import ThumbnailViewFileItem from './items/ThumbnailViewFileItem'
import ThumbnailViewFolderItem from './items/ThumbnailViewFolderItem'

type Props = {
  id: string
  className?: string
}

export const SortableThumbnailViewItem = observer(
  ({ id, className }: Props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id,
      data: { type: DragType.file, fileDragType: FileDragType.thumbnail }
    })

    const node = useFileNode(id)

    if (node) {
      return (
        <>
          <div
            className="w-full h-full"
            style={{
              transform: CSS.Transform.toString(transform) ?? '',
              transition: transition!
            }}
            ref={setNodeRef}
            {...attributes}
            {...listeners}>
            <ThumbnailViewItem
              id={id}
              className={className}
              isDragging={isDragging}
            />
          </div>
        </>
      )
    } else {
      return null
    }
  }
)

const ThumbnailViewItem = observer(
  ({ id, className, isDragging }: Props & { isDragging: boolean }) => {
    const node = useFileNode(id)

    const media = node ? mediaStore.medias[node.id] : null

    const isOfflined = media ? isErrored(media.mediaPlaybackState) : false

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      if (isOfflined) {
        modeStore.setReUploadMediaModal(true)
        return
      }

      if (isFolderNode(node)) {
        fileStore.setSelectedFolder(node.id)
      }
    }

    return (
      <ContextMenuWrapper
        className=""
        menuType={ContextMenuType.FileElement}
        id={id}
        props={{
          parentId: fileStore.getSelectedFolder()
        }}>
        <ToolTipWrapper
          className="block"
          disabled={!isOfflined}
          text={isOfflined ? MISSING_MEDIA_MESSAGE : MEDIA_UPLOADING_MESSAGE}>
          <div
            onClick={handleClick}
            // prevent double click events going to FileDialog
            onDoubleClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className={classNames(
              isDragging ? 'opacity-50' : 'opacity-100',
              'flex flex-col items-center group justify-center',
              className || ''
            )}>
            <div className="max-h-[160px] max-w-[200px] w-full">
              {isFolderNode(node) ? (
                <ThumbnailViewFolderItem />
              ) : (
                <ThumbnailViewFileItem
                  id={node!.id}
                  duration="0:15"
                  size={`${toFixed(node!.data.size / 1024 / 1024, 2)} MB`}
                  type={node!.data.type}
                  offline={isOfflined}
                />
              )}
            </div>
            <input
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              value={node?.label}
              onChange={(e) => {
                console.log(e.target.value, node?.id)
                fileStore.rename(e.target.value, node?.id || '')
              }}
              spellCheck={false}
              className="text-blueGray-500 outline-none group-hover:text-blueGray-200 transition duration-75 text-sm text-center bg-transparent w-full"
            />
          </div>
        </ToolTipWrapper>
      </ContextMenuWrapper>
    )
  }
)

export default ThumbnailViewItem

// This is the context menu for when the timeline element
import { observer } from 'mobx-react'
import React from 'react'

import { MediaElementId, MediaType, TimelineMode } from '@editor/interfaces'
import { useMediaElement } from '@editor/media/hooks'
import { detachAudio, mediaStore, modeStore, Video } from '@editor/state'
import { useCopy } from '@editor/state/clipboard/useHandleClipboardEvents'
import { layers } from '@editor/state/media/helpers/layers'
import { isProd, mediaElementNotDefinedError } from '@editor/utils'
import { BackspaceIcon, ChevronRightIcon } from '@heroicons/react/outline'

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTriggerItem,
  RightSlot
} from '../Primitives'

const TimelineElementMenu = ({ id }: { id: string }) => {
  const mediaElement = useMediaElement(id, true)

  if (!mediaElement) {
    throw mediaElementNotDefinedError(id)
  }

  const { handleCopy } = useCopy([id as MediaElementId])

  return (
    <>
      <ContextMenuContent
        sideOffset={5}
        // @ts-ignore
        align="end">
        <ContextMenuItem disabled>Cut</ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            handleCopy()
          }}>
          Copy
        </ContextMenuItem>
        <ContextMenuItem disabled>
          Duplicate <RightSlot>Cntrl+D</RightSlot>
        </ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            modeStore.toggleMode(TimelineMode.timeWarp)
          }}>
          Change clip speed
          <RightSlot>R</RightSlot>
        </ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            mediaStore.removeMediaElement(id)
          }}>
          Delete
          <RightSlot>
            <BackspaceIcon className="w-5 h-5"></BackspaceIcon>
          </RightSlot>
        </ContextMenuItem>
        <ContextMenuSeparator />
        {mediaElement?.type === MediaType.video && (
          <ContextMenuItem
            onSelect={() => {
              const media = mediaElement.media as Video
              detachAudio(media, id)
            }}>
            Detach Audio
          </ContextMenuItem>
        )}
        <ContextMenuSeparator />
        {!isProd && (
          <>
            <ContextMenuItem disabled>IN DEV ONLY</ContextMenuItem>
            <ContextMenuItem
              onSelect={() => {
                layers.bringToFront(mediaElement, mediaStore.tracks)
              }}>
              Bring to front
              <RightSlot>]</RightSlot>
            </ContextMenuItem>
            <ContextMenuItem
              onSelect={() => {
                layers.sendToBack(mediaElement, mediaStore.tracks)
              }}>
              Send to back
              <RightSlot>[</RightSlot>
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}
        <ContextMenu>
          <ContextMenuTriggerItem disabled>
            More Tools
            <RightSlot>
              <ChevronRightIcon className="w-4 h-4" />
            </RightSlot>
          </ContextMenuTriggerItem>
          <ContextMenuContent sideOffset={2} alignOffset={-5}>
            <ContextMenuItem>
              Save Page As… <RightSlot>⌘+S</RightSlot>
            </ContextMenuItem>
            <ContextMenuItem>Create Shortcut…</ContextMenuItem>
            <ContextMenuItem>Name Window…</ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem>Developer Tools</ContextMenuItem>
          </ContextMenuContent>
        </ContextMenu>
        <ContextMenuSeparator />
        <ContextMenuItem disabled>Rename</ContextMenuItem>
        <ContextMenuItem disabled>Reveal in Project</ContextMenuItem>
      </ContextMenuContent>
    </>
  )
}

export default observer(TimelineElementMenu)

import {
  FileData,
  MediaElementInterface,
  MediaId,
  MediaInterface,
  ModfyClipboard
} from '@editor/interfaces'
import { warnError } from '@editor/utils'

import { fileStore, mediaStore } from '../mobx'

import { setClipboard } from './idbClipboard'

export type JSONClipboard = {
  type: 'modfy/clipboard'
  mediaElements: MediaElementInterface[]
  medias: MediaInterface[]
  files: Record<MediaId, FileData>
}

export const copy = async (clipboard: ModfyClipboard, e?: ClipboardEvent) => {
  if (clipboard._type !== 'elements') {
    warnError('This clipboard type is not supported')
    return
  }

  // Elements are copied

  // Only unique elements are copied
  const medias = new Set<MediaInterface>()
  const files: Record<MediaId, FileData> = {}

  for (const element of clipboard.items) {
    const media = mediaStore.mediaObject(element.mediaId)

    medias.add(media)

    const file = fileStore.files[media._id]?.data

    if (file) {
      files[media._id] = file
    }
  }

  const mediaArr = Array.from(medias)

  const json: JSONClipboard = {
    type: 'modfy/clipboard',
    mediaElements: clipboard.items,
    medias: mediaArr,
    files
  }

  const jsonString = JSON.stringify(json)

  const modfyString = `<modfy>${jsonString}</modfy>`

  setClipboard(modfyString)

  if (e) {
    e.clipboardData?.setData('text/html', modfyString)

    console.log({ data: e.clipboardData?.getData('text/html') })
  }

  if (navigator.clipboard && ClipboardItem) {
    navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([modfyString], { type: 'text/html' })
      })
    ])
  }
}

import { observer } from 'mobx-react'
import React from 'react'

import { fileStore } from '@editor/state'

import MultiPanelView from './PanelView/MultiPanelViewer'
import ThumbnailView from './ThumbnailView/ThumbnailView'
import { FileDialog } from './FileDialog'

const LargeFileViewer = () => {
  return (
    <>
      <FileDialog background={true}>
        {fileStore.viewerMode === 'multi-panel' ? (
          <div
            onWheel={(e) => {
              e.stopPropagation()
            }}
            className="flex flex-row h-full overflow-x-auto scrollbar">
            <MultiPanelView />
          </div>
        ) : (
          <div
            onWheel={(e) => {
              e.stopPropagation()
            }}
            className="overflow-auto scrollbar h-full flex select-none">
            <ThumbnailView />
          </div>
        )}
      </FileDialog>
    </>
  )
}

export default observer(LargeFileViewer)

import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { createPanelMap } from '@editor/files/utils/createPanelMap'
import { PanelMap, useMultiPanelDragHandlersType } from '@editor/interfaces'
import { fileStore, useDragSlice } from '@editor/state/'
import { isProd } from '@editor/utils'

export const findPanel = (panels: PanelMap, id: string) => {
  if (id in panels) {
    return id
  }

  // BANG: Key from Object.keys
  return Object.keys(panels).find((key) => panels[key]!.includes(id))
}

export const useMultiPanelViewerHandlers =
  (): useMultiPanelDragHandlersType => {
    const [initialized, setInitialized] = useState<boolean>()

    const { activeId, setActiveId, panels, setPanels } = useDragSlice()

    const [clonedPanels, setClonedPanels] = useState<PanelMap | null>(null)

    // Updates the panel maps when the fileStore changes
    useEffect(() => {
      // use autorun and use `stateString` somewhere
      // so it runs on mobx state updates
      return autorun(() => {
        if (!isProd && isProd) console.log(fileStore.stateString)
        setPanels(createPanelMap())
      })
    }, [])

    const getPanelSelectionIdx = (id: string) => {
      if (id === 'root') return 0
      return fileStore.panelSelections.findIndex((p) => p === id)
    }

    const cancelDrag = () => {
      if (clonedPanels) {
        // Reset items to their original state in case items have been
        // Dragged across containers
        setPanels(clonedPanels)
        fileStore.syncToStore(clonedPanels)
      }

      setActiveId(null)
      setClonedPanels(null)
    }

    const onDragStart = ({ active }: DragStartEvent) => {
      if (!initialized) setInitialized(true)
      setActiveId(active.id)
      setClonedPanels(panels)
    }

    const onDragEnd = ({ active, over }: DragEndEvent) => {
      const activeContainer = findPanel(panels, active.id)

      if (!activeContainer) {
        setActiveId(null)
        return
      }
      const VOID_ID = 'void'

      const overId = over?.id || VOID_ID

      const overContainer = findPanel(panels, overId)

      const isFolder = !!panels[`container-${active.id}`]

      // Handling folder interactions:
      // - Cannot move folder into one of its sub-folders
      // - Moving a selected sub-folder into its parent cascades all panels and files selections
      //   after it
      if (isFolder) {
        // Is the folder highlighted?
        const isSelected = fileStore.fileSelections.find(
          (node) => node === activeId
        )

        // Active is a selected panel, get its index
        const panelSelectionIdx = getPanelSelectionIdx(activeId!)

        // Get the index of the panel that the folder item has been dragged into
        const activePanel = findPanel(panels, activeId!)!
        const activePanelIdx = getPanelSelectionIdx(
          activePanel.replace('container-', '')
        )

        // If the folder is selected, that means its sub-folder is open on the
        // next panel
        if (activePanelIdx >= 0 && isSelected) {
          // We are attempting to move a selected folder into its sub-folder,
          // this is a no no 🙅
          if (panelSelectionIdx <= activePanelIdx) {
            // NOTE: `onDragOver` still sets the items, which is fine since we cancel it here,
            // but there may be the possibility that `onDragEnd` does not get called and
            // so the state is not rectified... just a note in case we encounter this
            cancelDrag()
            return
          }

          // If we move a folder back and it has a sub-folder open,
          // we have to cascade all of those sub-folders.
          //
          // The fileSelectionId gives us the panel ID that this folder is
          // selected in, we want to collapse that panel and everything after.
          const fileSelectionId = fileStore.fileSelections.findIndex(
            (file) => file === activeId!
          )
          // Similarly doing the sleep(0) because for some reason this
          // change won't trigger a re-render
          setTimeout(() => fileStore.cascadePanelSelections(fileSelectionId), 0)
        }
      }

      let updateFileStore = false

      if (activeContainer && overContainer) {
        const activeIndex = panels[activeContainer]?.indexOf(active.id)
        const overIndex = panels[overContainer]?.indexOf(overId)

        if (
          activeIndex !== undefined &&
          overIndex !== undefined &&
          activeIndex !== overIndex
        ) {
          const newPanels = {
            ...panels,
            [overContainer]: arrayMove(
              // BANG: Checked in the IF statement
              panels[overContainer]!,
              activeIndex,
              overIndex
            )
          }

          setPanels(newPanels)
          fileStore.syncToStore(newPanels)
          updateFileStore = true
        }
      }

      if (!updateFileStore) fileStore.syncToStore(panels)
      setActiveId(null)
    }

    const onDragOver = (e: DragOverEvent) => {
      const { active, over } = e
      const overId = over?.id

      if (!overId) {
        return
      }

      const overContainer = findPanel(panels, overId)
      const activeContainer = findPanel(panels, active.id)

      if (!overContainer || !activeContainer) {
        return
      }

      if (activeContainer !== overContainer) {
        setPanels((items): PanelMap => {
          const activeItems = items[activeContainer]
          const overItems = items[overContainer]

          if (!activeItems || !overItems) {
            throw new Error('Active or over element is not defined')
          }

          const overIndex = overItems.indexOf(overId)
          const activeIndex = activeItems.indexOf(active.id)

          let newIndex: number

          if (overId in items) {
            newIndex = overItems.length + 1
          } else {
            const isBelowLastItem =
              over &&
              overIndex === overItems.length - 1 &&
              active.rect.current.translated &&
              active.rect.current.translated.offsetTop >
                over.rect.offsetTop + over.rect.height

            const modifier = isBelowLastItem ? 1 : 0

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1
          }

          // BANG: Too verbose to check and likely check all this stuff before and fail
          return {
            ...items,
            [activeContainer]: [
              ...items[activeContainer]!.filter((id) => id !== active.id)
            ],
            [overContainer]: [
              ...items[overContainer]!.slice(0, newIndex),
              items[activeContainer]![activeIndex]!,
              ...items[overContainer]!.slice(
                newIndex,
                items[overContainer]!.length
              )
            ]
          }
        })
      }
    }

    return {
      onMultiPanelDragStart: onDragStart,
      onMultiPanelDragEnd: onDragEnd,
      onMultiPanelDragCancel: cancelDrag,
      onMultiPanelDragOver: onDragOver
    }
  }

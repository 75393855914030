import { nanoid } from "nanoid";
import toast from "react-hot-toast";
import { tryDetectHevc } from "./hevcDetect";
import { transcodeHevc } from "./hevcTranscode";
import { createAnnoStore } from "./state";
export const needsUpload = (fileInput) => !(fileInput._type === "hevc" || fileInput._type === "loom" || fileInput._type === "gdrive" || fileInput._type === "reddit" || fileInput._type === "customFile");
export const fileInputId = (fileInput) => {
  if (fileInput._type === "hevc" || fileInput._type === "loom" || fileInput._type === "gdrive" || fileInput._type === "reddit" || fileInput._type === "customFile") {
    return fileInput.id;
  }
  return null;
};
export async function handleFileInput(file) {
  const { setFileInput, setLoading, nextStage } = createAnnoStore;
  const fileData = await file.arrayBuffer().then((buf) => new Uint8Array(buf));
  const isHevc = await tryDetectHevc(fileData);
  if (isHevc) {
    const id = nanoid();
    try {
      await toast.promise(transcodeHevc(id, file), {
        loading: "\u2699\uFE0F We're converting that video because it's in a format not supported by all browsers, this may take a minute or two...",
        success: "\u{1F389} The video has been successfully converted!",
        error: "\u{1F631} Something went wrong converting the video!'"
      });
    } catch (err) {
      return;
    }
    setLoading();
    const fileInput2 = { _type: "hevc", file, id };
    setFileInput(fileInput2);
    return fileInput2;
  }
  setLoading();
  const fileInput = { _type: "regular", file };
  setFileInput(fileInput);
  nextStage();
  return fileInput;
}

const FILE_NAME = "dummy";
export const tryDetectHevc = async (fileData) => {
  try {
    const isHevc = await detectHevc(fileData);
    return isHevc;
  } catch (err) {
    console.error("Error detecting HEVC", err);
    return false;
  }
};
export const detectHevc = async (fileData) => {
  if (typeof window.hevc_detect === "undefined")
    await loadWasmModule();
  const hevcDetectModule = window.hevc_detect;
  const hevcDetect = await hevcDetectModule();
  const FS = hevcDetect.FS;
  FS.writeFile(FILE_NAME, fileData);
  const fileBuf = hevcDetect.intArrayFromString(FILE_NAME);
  const fileBufPtr = hevcDetect._malloc(fileBuf.length);
  hevcDetect.writeArrayToMemory(fileBuf, fileBufPtr);
  const isHevc = hevcDetect._detect_hevc(fileBufPtr);
  console.log("isHevc", isHevc);
  return isHevc === 1;
};
function loadWasmModule() {
  return new Promise((resolve, reject) => {
    var _a;
    const script = document.createElement("script");
    const eventHandler = () => {
      if (typeof window.hevc_detect === "undefined") {
        return reject(new Error("failed to get hevc detect"));
      }
      return resolve();
    };
    script.src = "/wasm/hevc-detect.js";
    script.type = "text/javascript";
    script.addEventListener("load", eventHandler, { once: true });
    (_a = document.getElementsByTagName("head")[0]) == null ? void 0 : _a.appendChild(script);
  });
}

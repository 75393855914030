import { autorun, toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { DragOverlay } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { ContextMenuType, ContextMenuWrapper } from '@editor/context-menu'
import { handleFileDialogEvent } from '@editor/files/utils/fileUtils'
import { DragType } from '@editor/interfaces'
import { fileStore, useDragSlice } from '@editor/state'
import { isProd, warnError } from '@editor/utils'
import { PlusIcon } from '@heroicons/react/outline'

import { FileDialog } from '../FileDialog'

import ThumbnailViewItem, {
  SortableThumbnailViewItem
} from './ThumbnailViewItem'

const selectedFolderToItems = () => {
  return fileStore.folders[fileStore.getSelectedFolder()]?.children
}

const ThumbnailView = () => {
  const { activeId, thumbnailItems, setThumbnailItems, dragType } =
    useDragSlice()

  const [isClient, setIsClient] = useState(false)

  const activeIndex = activeId ? thumbnailItems.indexOf(activeId) : -1

  // If the fileStore is updated then sync the changes to this local state
  useEffect(() => {
    // mobx state observing
    return autorun(() => {
      const str =
        fileStore.folders[fileStore.getSelectedFolder()]?.children.toString()
      if (isProd !== undefined && isProd === false)
        console.log(`${fileStore.selectedFoldersBreadcrumb}-${str}`)
      const items = selectedFolderToItems()

      if (!items) {
        warnError('Folder Item is not defined')
        return
      }

      setThumbnailItems(toJS(items))
    })
  }, [])

  // Set client (for SSR)
  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    // <DndContext
    //   collisionDetection={closestCorners}
    //   onDragCancel={onThumbnailDragCancel}
    //   onDragStart={onThumbnailDragStart}
    //   onDragEnd={onThumbnailDragEnd}>
    <div className="h-full flex-1 pointer-events-auto">
      <ContextMenuWrapper
        menuType={ContextMenuType.FileMenu}
        className="h-full"
        props={{ parentId: `container-${fileStore.getSelectedFolder()}` }}>
        {thumbnailItems.length > 0 ? (
          <div
            id={`container-${fileStore.getSelectedFolder()}`}
            className="grid grid-cols-3 py-4 2xl:grid-cols-4 grid-flow-row gap-2 gap-x-8 h-full">
            <SortableContext
              items={thumbnailItems}
              strategy={rectSortingStrategy}>
              {thumbnailItems.map((id) => (
                <SortableThumbnailViewItem key={id} id={id} />
              ))}
            </SortableContext>
          </div>
        ) : (
          <div className="h-full pointer-events-none">
            <FileDialog handleFileChange={handleFileDialogEvent}>
              {({ handleFileClick }) => (
                <button
                  onClick={handleFileClick}
                  className="focus:outline-none py-4 h-full w-full flex items-center justify-center flex-col">
                  <PlusIcon className="h-16 w-16 m-0 text-gray-300 opacity-30"></PlusIcon>
                  <p className="text-lg text-gray-500 py-4">Add files</p>
                </button>
              )}
            </FileDialog>
          </div>
        )}
        <div>
          {isClient &&
            dragType === DragType.file &&
            createPortal(
              <DragOverlay adjustScale={false}>
                {activeId && (
                  <ThumbnailViewItem
                    isDragging={true}
                    // BANG: ActiveIndex is from the thumbnailsItems only
                    id={thumbnailItems[activeIndex]!}
                  />
                )}
              </DragOverlay>,
              document.body
            )}
        </div>
      </ContextMenuWrapper>
    </div>
    // </DndContext>
  )
}

export default observer(ThumbnailView)

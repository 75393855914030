import axios from "axios";
import { nanoid } from "nanoid";
import toast from "react-hot-toast";
import { GDRIVE_CF_URL } from "../../util/const";
import { handleFileInput } from "../../util/selection";
import { createAnnoStore } from "../../util/state";
export const useAnnotateSelection = () => {
  const { setFileInput, setLoading, inputSelectionType, url } = createAnnoStore;
  const onFileInput = async (e) => {
    var _a;
    const file = (_a = e.target.files) == null ? void 0 : _a.item(0);
    if (!file)
      return;
    handleFileInput(file);
  };
  const handleGdriveUrl = async () => {
    const gDriveUrl = new URL(url);
    if (!url.includes("drive.google.com")) {
      toast.error("URL does not contain a google drive link");
      return;
    }
    const gDriveID = gDriveUrl.pathname.split("/")[3];
    setLoading();
    const id = nanoid();
    await toast.promise(axios.get(`${GDRIVE_CF_URL}?gDriveId=${gDriveID}&fileId=${id}`), {
      loading: "Getting the file from google...",
      error: (e) => {
        console.error(`Error fetching gdrive file`, e);
        return "Unable to fetch gdrive file";
      },
      success: "Fetched gdrive file"
    });
    console.log("Got gDrive file");
    const videoUrl = `/api/cdn/${id}`;
    const downloadingGdrive = toast.loading("Downloading gdrive file");
    const fetchVideo = await fetch(videoUrl);
    const file = new File([await fetchVideo.blob()], "gdriveVideo", {
      type: "video/mp4"
    });
    toast.success("Downloaded file", { id: downloadingGdrive });
    setFileInput({ _type: "gdrive", file, id });
    createAnnoStore.nextStage();
  };
  const handleLoomUrl = async () => {
    let loomUrl = url;
    if (!loomUrl.includes("loom.com")) {
      toast.error("Url does not contain a loom link");
      return;
    }
    if (loomUrl.includes("share")) {
      loomUrl = loomUrl.replace("share", "embed");
    }
    if (!loomUrl.includes("embed")) {
      toast.error("URL not formatted correctly");
      return;
    }
    setLoading();
    console.log("URL", loomUrl);
    const id = nanoid();
    const response = await toast.promise(axios.get(`/api/cdn/loom?url=${loomUrl}&id=${id}`), {
      loading: "Fetching the loom...",
      error: (e) => {
        console.error("Error fetching loom", e);
        return "Unable to fetch loom url";
      },
      success: (response2) => {
        const { result: result2 } = response2.data;
        if (result2) {
          return "Fetched loom url";
        }
        throw Error("Unable to fetch loom url");
      }
    });
    const { result } = response.data;
    if (result) {
      console.log("Result", result);
      const downloadingLoom = toast.loading("Downloading loom");
      const fetchVideo = await fetch(result);
      const file = new File([await fetchVideo.blob()], "loomVideo", {
        type: "video/mp4"
      });
      toast.success("Downloaded loom", { id: downloadingLoom });
      setFileInput({ _type: "loom", file, id });
      createAnnoStore.nextStage();
    }
  };
  const handleRedditUrl = async () => {
    if (!url.includes("reddit.com")) {
      toast.error("URL does not contain a valid reddit link");
      return;
    }
    const id = nanoid();
    setLoading();
    await toast.promise(axios.post(`${GDRIVE_CF_URL}reddit`, {
      id,
      url
    }), {
      loading: "Fetching reddit post...",
      error: (e) => {
        console.error(`Error fetching reddit file`, e);
        return "Unable to fetch video, is it a reddit video post?";
      },
      success: "Fetched!"
    });
    console.log("Got reddit file");
    const videoUrl = `/api/cdn/${id}`;
    const downloadingReddit = toast.loading("Loading reddit video now...");
    const fetchedVideo = await fetch(videoUrl).then((r) => r.blob());
    const file = new File([fetchedVideo], "redditVideo", {
      type: "video/mp4"
    });
    toast.success("Loaded video from reddit!", { id: downloadingReddit });
    setFileInput({ _type: "reddit", file, id });
    createAnnoStore.nextStage();
  };
  const handleCustomLink = async () => {
    const id = nanoid();
    setLoading();
    const response = await toast.promise(axios.post(`${GDRIVE_CF_URL}customLink`, { id, url }), {
      loading: "Fetching file...",
      error: (e) => {
        console.error(`Error fetching file`, e);
        return "Unable to fetch file, is it a valid video/audio file?";
      },
      success: "Fetched!"
    });
    console.log("Got custom file");
    const { contentType } = response.data;
    if (!contentType || !contentType.startsWith("video") && !contentType.startsWith("audio")) {
      toast.error("File is not a valid video or audio file");
      return;
    }
    const mediaUrl = `/api/cdn/${id}`;
    const fileDownloadingToast = toast.loading("Loading media now...");
    const fetchedFile = await fetch(mediaUrl).then((r) => r.blob());
    const file = new File([fetchedFile], "customLink", {
      type: contentType
    });
    toast.success("Loaded media!", { id: fileDownloadingToast });
    setFileInput({ _type: "customFile", file, id });
    createAnnoStore.nextStage();
  };
  const handleAddEmbed = async (e) => {
    e == null ? void 0 : e.preventDefault();
    console.log("Submit event received");
    if (!inputSelectionType)
      return;
    if (inputSelectionType === "loom")
      return handleLoomUrl();
    if (inputSelectionType === "gdrive")
      return handleGdriveUrl();
    if (inputSelectionType === "reddit")
      return handleRedditUrl();
    if (inputSelectionType === "customLink")
      return handleCustomLink();
    setLoading();
    setFileInput({
      _type: "thirdPartEmbed",
      url,
      embedType: inputSelectionType
    });
    createAnnoStore.nextStage();
  };
  return { handleAddEmbed, onFileInput };
};

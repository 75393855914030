// This is the context menu for when the timeline element
import { observer } from 'mobx-react'
import React from 'react'

import { setScale, timelineStore, useAppDispatch } from '@editor/state'
import { ZoomInIcon, ZoomOutIcon } from '@heroicons/react/outline'

import { ContextMenuContent, ContextMenuItem, RightSlot } from '../Primitives'

const TimelineMenu = () => {
  const dispatch = useAppDispatch()

  const minWidth = timelineStore.globalTimelineRef.current?.offsetWidth || 0

  return (
    <>
      <ContextMenuContent
        sideOffset={5}
        //   @ts-ignore
        align="end">
        <ContextMenuItem
          onSelect={() => {
            dispatch(
              setScale({
                scale: 10,
                minWidth: minWidth,
                increment: true
              })
            )
          }}>
          Zoom in timeline
          <RightSlot>
            <ZoomInIcon className="w-5 h-5"></ZoomInIcon>
          </RightSlot>
        </ContextMenuItem>
        <ContextMenuItem
          onSelect={() => {
            dispatch(
              setScale({
                scale: -10,
                minWidth: minWidth,
                increment: true
              })
            )
          }}>
          Zoom out timeline
          <RightSlot>
            <ZoomOutIcon className="w-5 h-5"></ZoomOutIcon>
          </RightSlot>
        </ContextMenuItem>

        <ContextMenuItem disabled>Paste</ContextMenuItem>
        <ContextMenuItem disabled>Select All</ContextMenuItem>
      </ContextMenuContent>
    </>
  )
}

export default observer(TimelineMenu)

import * as Y from "yjs";
import { client } from "@editor/graphql/apollo/ApolloProvider";
import {
  UpdateAnnotateProjectStateDocument
} from "@graphql/schema/client";
export async function saveAnnotateProjectState(yDoc, projectId, version, userId) {
  console.log("saveAnnotateProjectState\nproject:", projectId, "version:", version, "user:", userId);
  const binaryData = Y.encodeStateAsUpdate(yDoc);
  const base64 = uint8ArrayToBase64(binaryData);
  await client.mutate({
    mutation: UpdateAnnotateProjectStateDocument,
    variables: {
      updateProjectState: {
        userId,
        projectId,
        updates: base64,
        version
      }
    }
  });
  console.log("saveAnnotateProjectState completed");
}
function uint8ArrayToBase64(bytes) {
  let binary = "";
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

/* This example requires Tailwind CSS v2.0+ */
import { observer } from 'mobx-react'
import React, { Fragment } from 'react'

import { modeStore } from '@editor/state'
import { Transition } from '@headlessui/react'

import FileViewerBreadcrumb from './components/Breadcrumb'
import LargeFileViewer from './components/LargeFileViewer'
import MissingFilesModal from './components/MissingFilesModal'

const FileTray = () => {
  return (
    <>
      <div
        className="flex flex-1 ml-0 self-stretch overflow-hidden pointer-events-none"
        style={{ flexGrow: modeStore.showFilePicker ? 1 : 0.00001 }}>
        <Transition.Root show={modeStore.showFilePicker} as={Fragment}>
          <div
            style={{ marginLeft: 0 }}
            className="flex flex-1 ml-0 self-stretch overflow-hidden pointer-events-none">
            <div className="flex-1 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200"
                leaveFrom="translate-x-full"
                leaveTo="-translate-x-full">
                <div className="w-full h-full flex overflow-y-hidden bg-blueGray-800 bg-opacity-20 border-r-2 border-blueGray-700 border-opacity-10">
                  <div className="h-full flex-1 overflow-hidden flex flex-col pointer-events-auto divide-y-2 divide-blueGray-700 divide-opacity-10">
                    <div className="mt-2 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div
                          className="h-full rounded-md border-dashed border-blueGray-500"
                          aria-hidden="true">
                          <LargeFileViewer />
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                    <div className="px-4 py-2">
                      <div className="flex flex-row justify-between">
                        <FileViewerBreadcrumb />
                        {/* <FileViewerSize /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Transition.Root>
      </div>

      <MissingFilesModal />
    </>
  )
}

export default observer(FileTray)

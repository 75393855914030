import classNames from 'classnames'
import React, { useRef } from 'react'

import { handleFileDialogEvent } from '../utils/fileUtils'

type Children<T extends boolean> = T extends true
  ? React.ReactNode
  : ({
      // eslint-disable-next-line no-unused-vars
      handleFileClick
    }: {
      handleFileClick: () => void
    }) => React.ReactNode

type FileDialogProps<T extends boolean> = {
  children: Children<T>
  handleFileChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void
  background?: T
  className?: string
}

export const FileDialog = <T extends boolean>({
  children,
  handleFileChange,
  background,
  className
}: FileDialogProps<T>) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileClick = () => {
    const { current } = inputRef

    if (current) {
      current.click()
    } else {
      console.error('Input element not defined')
    }
  }

  if (background) {
    return (
      <>
        <input
          onChange={handleFileDialogEvent}
          ref={inputRef}
          type={'file'}
          className="hidden fixed"></input>
        <button
          onDoubleClick={handleFileClick}
          className={classNames(
            'focus:outline-none',
            className || 'h-full w-full'
          )}>
          {children}
        </button>
      </>
    )
  } else {
    return (
      <>
        <input
          onChange={handleFileChange}
          ref={inputRef}
          type={'file'}
          className="hidden fixed"></input>
        {children && (children as Children<false>)({ handleFileClick })}
      </>
    )
  }
}

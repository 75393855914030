import { observer } from 'mobx-react'
import React, { ReactNode } from 'react'

import { useDroppable } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ContextMenuType, ContextMenuWrapper } from '@editor/context-menu'
import { DragType, FileDragType, FilePanelIndex } from '@editor/interfaces'
import { classNames } from '@editor/utils/classnames'

import PanelItem from './PanelItem'

type PanelProps = { id: string; children: ReactNode }

const Panel = ({ id, children }: PanelProps) => {
  const { setNodeRef } = useDroppable({
    id,
    data: { type: DragType.file, fileDragType: FileDragType.multiPanel }
  })

  return (
    <ContextMenuWrapper
      menuType={ContextMenuType.FileMenu}
      props={{ parentId: id }}>
      <div ref={setNodeRef} className="flex flex-col w-full h-full">
        {children}
      </div>
    </ContextMenuWrapper>
  )
}

type PanelElementProps = { id: string; parentId: string; idx: number }

const PanelElement = observer(({ id, parentId, idx }: PanelElementProps) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    data: {
      type: DragType.file,
      fileDragType: FileDragType.multiPanel
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform) ?? '',
    transition
  }

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        'shadow-none',
        isDragging ? 'opacity-10' : 'opacity-100'
      )}
      style={style as React.CSSProperties}
      {...attributes}
      {...listeners}>
      <PanelItem parentId={parentId} id={id} idx={idx as FilePanelIndex} />
    </div>
  )
})

export { PanelElement }

export default observer(Panel)

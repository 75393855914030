import { observer } from "mobx-react";
import React from "react";
import { useIsMobile } from "@editor/utils/hooks";
import { createAnnoStore } from "../../util/state";
export const selections = {
  loom: {
    name: "loom",
    label: "Loom",
    image: "/images/logos/loom.svg",
    placeholder: "https://loom.com/share/"
  },
  gdrive: {
    name: "gdrive",
    label: "Google Drive",
    image: "/images/logos/drive.svg",
    placeholder: "https://drive.google.com/file/d/"
  },
  youtube: {
    name: "youtube",
    label: "YouTube",
    image: "/images/logos/youtube.svg",
    placeholder: "https://youtu.be/"
  },
  reddit: {
    name: "reddit",
    label: "Reddit",
    image: "/images/logos/reddit.svg",
    placeholder: "https://www.reddit.com/"
  },
  soundcloud: {
    name: "soundcloud",
    label: "SoundCloud",
    image: "/images/logos/soundcloud.svg",
    placeholder: "https://soundcloud.com/"
  },
  customLink: {
    name: "customLink",
    label: "Custom link",
    image: "/images/logos/link.svg",
    placeholder: "https://example.com/video.mp4"
  }
};
export const AnnotateHomeSelection = observer(({
  onFileInput,
  selectors,
  hideAudio,
  hideVideo
}) => {
  const { isMobile } = useIsMobile();
  const validSelectors = selectors != null ? selectors : Object.keys(selections);
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col space-y-3"
  }, validSelectors.map((key) => {
    const selection = selections[key];
    if (!selection)
      return null;
    return /* @__PURE__ */ React.createElement("button", {
      key: selection.name,
      onClick: () => {
        createAnnoStore.setInputSelectionType(selection.name);
        createAnnoStore.nextStage();
      },
      className: "bg-gray-300 bg-opacity-5 hover:bg-opacity-10 py-2.5 px-3 w-full rounded-md flex items-center group transition focus:outline-none"
    }, /* @__PURE__ */ React.createElement("img", {
      alt: `${selection.name} Logo`,
      src: selection.image,
      className: "w-6 h-5"
    }), /* @__PURE__ */ React.createElement("p", {
      className: "pl-2 capitalize text-white text-opacity-60 group-hover:text-opacity-90 transition"
    }, selection.label));
  }), !hideVideo && /* @__PURE__ */ React.createElement("label", {
    className: "relative bg-gray-300 bg-opacity-5 hover:bg-opacity-10 py-2.5 px-3 w-full rounded-md flex items-center group transition focus:outline-none cursor-pointer",
    htmlFor: "videoInput"
  }, /* @__PURE__ */ React.createElement("img", {
    alt: "Video File",
    src: "/images/logos/video.svg",
    className: "w-6 h-5"
  }), /* @__PURE__ */ React.createElement("p", {
    className: "pl-2 capitalize text-white text-opacity-60 group-hover:text-opacity-90 transition"
  }, "Video File"), /* @__PURE__ */ React.createElement("input", {
    type: "file",
    accept: "video/*",
    className: "absolute opacity-0 w-0 h-0",
    id: "videoInput",
    onChange: onFileInput
  })), !hideAudio && /* @__PURE__ */ React.createElement("label", {
    className: "relative bg-gray-300 bg-opacity-5 hover:bg-opacity-10 py-2.5 px-3 w-full rounded-md flex items-center group transition focus:outline-none cursor-pointer",
    htmlFor: "audioInput"
  }, /* @__PURE__ */ React.createElement("img", {
    alt: "Audio File",
    src: "/images/logos/audio.svg",
    className: "w-6 h-5"
  }), /* @__PURE__ */ React.createElement("p", {
    className: "pl-2 capitalize text-white text-opacity-60 group-hover:text-opacity-90 transition"
  }, "Audio File"), /* @__PURE__ */ React.createElement("input", {
    type: "file",
    accept: "audio/*",
    className: "absolute opacity-0 w-0 h-0",
    id: "audioInput",
    onChange: onFileInput
  })), !isMobile && /* @__PURE__ */ React.createElement("p", {
    className: "text-center text-white text-opacity-30 py-4"
  }, "or drag and drop a file"));
});

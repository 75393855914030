import React, { useEffect, useRef, useState } from 'react'

import { classNames } from '@editor/utils/classnames'
import { Transition } from '@headlessui/react'
import { CloudUploadIcon } from '@heroicons/react/outline'

import { handleFileDropEvent } from './utils/fileUtils'

const useDragEvents = () => {
  const [isDragging, setDragging] = useState(false)
  /**
   * When dragging multiple events are fired from the different children the cursor goes over
   * So we keep a count of how many drags have been started
   * When this is > 0, it means a drag is in progress,
   * When this is 0, it means no drags are in progress
   */
  const dragCount = useRef(0)

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault()
  }

  useEffect(() => {
    const onDragStart = (event: DragEvent) => {
      event.stopPropagation()
      event.preventDefault()
      // increase the drags in progress
      dragCount.current++
      setDragging(true)
    }

    const onDragEnd = (event: DragEvent) => {
      event.stopPropagation()
      event.preventDefault()
      // decrease the drags in progress
      dragCount.current--
      if (dragCount.current === 0) setDragging(false)
    }

    window.addEventListener('dragenter', onDragStart)
    window.addEventListener('dragleave', onDragEnd)

    return () => {
      window.removeEventListener('dragenter', onDragStart)
      window.removeEventListener('dragleave', onDragEnd)
    }
  }, [])

  const attributes = { onDragOver }

  return { isDragging, setDragging, attributes }
}

const FileDragNDrop = ({
  children,
  handleDrop
}: {
  children: React.ReactNode
  handleDrop?: (_e: React.DragEvent<HTMLDivElement>) => void
}) => {
  const { isDragging, setDragging, attributes } = useDragEvents()

  return (
    <div
      onDrop={(e) => {
        setDragging(false)
        if (handleDrop) {
          handleDrop(e)
        } else {
          handleFileDropEvent(e)
        }
      }}
      className="grid"
      {...attributes}>
      <div
        className={classNames(
          'transition duration-75 ease-in-out stackedLayer',
          isDragging && 'filter blur-sm'
        )}>
        {children}
      </div>

      <Transition
        as={'div'}
        className="stackedLayer flex"
        show={isDragging}
        enter="transition-opacity duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed inset-0 z-20 bg-gray-900 bg-opacity-70 transition-opacity"></div>
        <div className="fixed inset-0 z-30 grid w-full h-full place-items-center">
          <div className="flex flex-col items-center select-none space-y-4 justify-center">
            <p className="text-lg">
              <CloudUploadIcon className="text-indigo-500 animate-pulse w-32 h-auto"></CloudUploadIcon>
            </p>
            <p className="text-3xl text-indigo-400">Drop media to add</p>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default FileDragNDrop

import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'

import { ToolTipWrapper } from '@editor/components/Radix/Tooltip'
import { mediaStore, modeStore } from '@editor/state'
import { isErrored } from '@editor/state/media'
import { warnError } from '@editor/utils'
import { classNames } from '@editor/utils/classnames'
import { Dialog, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ExclamationIcon,
  UploadIcon,
  XIcon
} from '@heroicons/react/outline'
import { CheckCircledIcon } from '@radix-ui/react-icons'

import { getFileSha256 } from '../utils/fileUtils'

type MissingFiles = Record<
  string,
  {
    id: string
    label: string
    uploaded: boolean
    sha256: string
    matchesOriginalFile?: boolean
    selectedFile?: File
  }
>

const MissingFilesModal = () => {
  // The modal is shown automatically the first time and
  // then it is only shown when user opens it from command palette
  const [missingMedias, setMissingMedias] = useState<MissingFiles>({})

  const hasMissingMedias = Object.values(missingMedias).length > 0

  /**
   * Open up the modal if there are any missing files
   * only the first time
   */
  useEffect(() => {
    const shouldShowModal = Object.values(missingMedias).find(
      ({ uploaded }) => !uploaded
    )

    if (shouldShowModal) {
      modeStore.setReUploadMediaModal(true)
    }
  }, [missingMedias])

  useEffect(() => {
    /**
     * Observes when mediaStore.medias changes and listens for
     * 1. Any media going offline
     * 2. Any files currently in the state being uploaded
     * Then updates the missingMedias state accordingly
     */
    return reaction(
      () => {
        // This will re run on when any mobx observables used in this func update
        // This return value is passed as first parameter to next function
        return Object.values(mediaStore.medias).map((media) => ({
          _id: media._id,
          mediaPlaybackState: media.mediaPlaybackState,
          file: media.getFileStoreFile()
        }))
      },
      (medias) => {
        // Update the state to reflect changes
        setMissingMedias((val) => {
          const missingMedias = { ...val }

          medias.forEach((media) => {
            const { _id: id, file, mediaPlaybackState } = media
            if (!file) return
            const { label, data } = file
            const { sha256 } = data

            // Get if media has error
            const errored = isErrored(mediaPlaybackState)

            // The data already in the state or new object to be added
            const mediaData = missingMedias[id] || { id, label, sha256 }

            if (missingMedias[id] && !errored) {
              // If the media is present in the state and is not errored anymore set uploaded true
              missingMedias[id] = { ...mediaData, uploaded: true }
            } else if (errored) {
              // If this media is missing add it to the state (if it is already in the state it will be overwritten)
              missingMedias[id] = { ...mediaData, uploaded: false }
            }
          })

          return missingMedias
        })
      }
    )
  }, [])

  const handleFileInput = async (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = e.target.files && e.target.files[0]
    if (!selectedFile) return null

    const fileHash = await getFileSha256(selectedFile)
    const matchesOriginalFile = fileHash === missingMedias[id]?.sha256

    if (matchesOriginalFile) {
      const media = mediaStore.medias[id]
      media?.updateFile(selectedFile)
    }

    setMissingMedias((files) => {
      const newFiles = { ...files }

      const newFile = newFiles[id]

      if (!newFile) {
        warnError('setMissing missing medias bug')
        return files
      }
      newFiles[id] = {
        ...newFile,
        uploaded: true,
        selectedFile,
        matchesOriginalFile
      }
      return newFiles
    })
  }

  const onClose = () => {
    modeStore.setReUploadMediaModal(false)
    // Remove uploaded files from the state before closing so they dont show up when opened next time
    setMissingMedias((value) => {
      const _missingMedias = { ...value }
      Object.values(missingMedias).forEach((file) => {
        if (file.uploaded) {
          delete _missingMedias[file.id]
        }
      })
      return _missingMedias
    })
  }

  return (
    <Transition.Root show={modeStore.reUploadMediaModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {}}>
        <div className="grid min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
          <div className="stackedLayer">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="w-full h-full bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
          </div>

          <div className="stackedLayer flex items-center justify-center pointer-events-none">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="pointer-events-auto inline-block align-bottom bg-blueGray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-3xl w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={onClose}>
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <div
                    className={classNames(
                      'mx-auto flex items-center justify-center h-12 w-12 rounded-full',
                      hasMissingMedias ? 'bg-red-200' : 'bg-green-200'
                    )}>
                    {hasMissingMedias ? (
                      <ExclamationIcon
                        className="h-6 w-6 rounded-full text-red-700"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="h-6 w-6 rounded-full text-green-700"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl leading-6 font-medium text-white">
                      {hasMissingMedias
                        ? 'Some files are missing'
                        : 'No missing files'}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-400">
                        {hasMissingMedias
                          ? 'The following files are missing and you need to re-upload them.'
                          : 'All your files are present, nothing to reupload'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6">
                  <ul className="divide-y-2 divide-blueGray-700">
                    {Object.values(missingMedias).map((file) => {
                      const isMissMatch =
                        file.selectedFile && !file.matchesOriginalFile

                      const tooltipText = isMissMatch
                        ? "The selected file doesn't match the file that was added previously"
                        : file.selectedFile
                        ? 'The re-uploaded file matches with old file'
                        : ''

                      return (
                        <li key={file.id} className="block text-white py-4">
                          <div className="flex items-center">
                            <div className="flex-1 truncate">{file.label}</div>

                            <ToolTipWrapper
                              disabled={!tooltipText}
                              text={tooltipText}>
                              {isMissMatch ? (
                                <ExclamationIcon className="w-5 h-5 text-yellow-600 mr-2" />
                              ) : file.uploaded ? (
                                <CheckCircledIcon className="w-5 h-5 text-green-600 mr-2" />
                              ) : null}
                            </ToolTipWrapper>

                            {!file.uploaded && (
                              <label
                                htmlFor={`${file.id}-upload`}
                                className="relative ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span>
                                  {file.selectedFile
                                    ? 'Re-Select file'
                                    : 'Select file'}
                                </span>
                                <UploadIcon className="h-5 w-5 ml-2" />
                                <input
                                  id={`${file.id}-upload`}
                                  name={`${file.id}-upload`}
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) => handleFileInput(file.id, e)}
                                />
                              </label>
                            )}
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default observer(MissingFilesModal)
